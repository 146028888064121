<template>
  <div>
    <div>
<!--      1-->
      <div class="analyseresourceNubmberFlex">
        <div>
          <p>
            <span class="analyseBackground"></span>
            <span>总课程数</span>
          </p>
          <p class="analyseresourceNubmberT">{{courseClassfyTotal}}</p>
        </div>
        <div>
          <p>
            <span class="analyseBackground"></span>
            <span>图文资源</span>
          </p>
          <p class="analyseresourceNubmberT">{{courseNumbetTotal.teacher_resource_total_number}}</p>
        </div>
        <div>
          <p>
            <span class="analyseBackground"></span>
            <span>题库统计</span>
          </p>
          <p class="analyseresourceNubmberT">{{exercisesTotal}}</p>
        </div>
      </div>
<!--      2-->
      <div class="analyseresourceStatistics">
<!--        资源总数-->
        <div class="analyseresourceBoxShow">
          <p>
            <span class="analyseBackground"></span>
            <span>资源总数</span>
          </p>
          <div>
            <div class="collectionFlex collectionImgPad" >
              <img src="images/echats1.png">
              <div>
                <p class="collectionNubmber">{{parseInt(courseResourceNumbetTotal.teacher_resource_total_number)+parseInt(courseNumbetTotal.teacher_resource_total_number)}}</p>
                <p>专辑</p>
              </div>
            </div>
            <div class="resourceStatisText">
              <div>
                <p>资源大小</p>
                <p>{{(courseResourceNumbetTotal.teacher_resource_total_size/1024/1024/1024+courseNumbetTotal.teacher_resource_total_size/1024/1024/1024).toFixed(2)}}GB</p>
              </div>
            </div>
          </div>
        </div>
<!--        校内图文资源-->
        <div class="analyseresourceBoxShow">
          <p>
            <span class="analyseBackground"></span>
            <span>校内图文资源</span>
          </p>
          <div>
            <div class="collectionFlex collectionImgPad" >
              <img src="images/eacharts2.png">
              <div>
                <p class="collectionNubmber">{{courseResourceNumbetTotal.teacher_resource_total_number}}</p>
                <p>套</p>
              </div>
            </div>
            <div class="resourceStatisText">
              <div>
                <p>资源大小</p>
                <p>{{ (courseResourceNumbetTotal.teacher_resource_total_size/1024/1024/1024).toFixed(2)}}GB</p>
              </div>
            </div>
          </div>
        </div>
<!--        校内试卷统计-->
        <div class="analyseresourceBoxShow">
          <p>
            <span class="analyseBackground"></span>
            <span>校内试卷统计</span>
          </p>
          <div>
            <div class="collectionFlex collectionImgPad" >
              <img src="images/echarts3.png">
              <div>
                <p class="collectionNubmber">{{courseNumbetTotal.teacher_resource_total_number}}</p>
                <p>套</p>
              </div>
            </div>
            <div class="resourceStatisText">
              <div>
                <p>资源大小</p>
                <p>{{ (courseNumbetTotal.teacher_resource_total_size/1024/1024/1024).toFixed(2)}}GB</p>
              </div>
            </div>
          </div>
        </div>
<!--        校内题库统计-->
        <div class="analyseresourceBoxShow">
          <p>
            <span class="analyseBackground"></span>
            <span>校内题库统计</span>
          </p>
          <div>
            <div class="collectionFlex collectionImgPad" >
              <img src="images/echarts4.png">
              <div>
                <p class="collectionNubmber">{{schoolExercisesNumber.exercises_total_number}}</p>
                <p>题</p>
              </div>
            </div>
            <div class="resourceStatisText">
              <div>
                <p>使用总数量</p>
                <p>{{schoolExercisesNumber.use_number}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      3-->
      <div class="analyseresourceclassfyFlex">
        <p >
          <span class="analyseBackground"></span>
          <span>课程分析</span>
        </p>
        <div >
          <echarts id="courseClassfy" :data="courseClassfy" :echartsTitle="echartscourseFyTitle" :legendShow=true></echarts>
        </div>
      </div>
<!--4-->
      <div class="analyseresourceNubmberku">
        <p>
          <span class="analyseBackground"></span>
          <span>题库分析</span>
        </p>
        <div>
          <div class="echartsMbtom">
            <div style="width: 45%;height: 300px">
              <echarts id="statisticsSchoolClassfy" :data="statisticsSchoolClassfy" :echartsTitle="statisticsClassfyTitle" :legendShow=false></echarts>
            </div>
            <div style="width: 45%;height: 300px">
              <echarts id="startExercises" :data="startExercises" :echartsTitle="statisticsStartTitle" :legendShow=false></echarts>
            </div>
          </div>
          <template>
            <el-table
                :data="statisticsStart"
                border
                style="width: 100%">
              <el-table-column
                  prop="exercises_type"
                  label="题目类型"
                  >
                <template slot-scope="scope">
                  <p v-if="scope.row.exercises_type=='TF'">判断题</p>
                  <p v-if="scope.row.exercises_type=='SC'">单选题</p>
                  <p v-if="scope.row.exercises_type=='MC'">多选题</p>
                  <p v-if="scope.row.exercises_type=='FB'">填空题</p>
                  <p v-if="scope.row.exercises_type=='SAQ'">简答题</p>
                </template>
              </el-table-column>
              <el-table-column
                  prop="one_number"
                  label="一星"
                  >
              </el-table-column>
              <el-table-column
                  prop="two_number"
                  label="二星">
              </el-table-column>
              <el-table-column
                  prop="three_number"
                  label="三星"
                  >
              </el-table-column>
              <el-table-column
                  prop="four_number"
                  label="四星"
                  >
              </el-table-column>
              <el-table-column
                  prop="five_number"
                  label="五星">
              </el-table-column>
              <el-table-column
                  prop="total_exercises_number"
                  label="题目总数"
                  >
              </el-table-column>
            </el-table>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {statisticsSchoolstart,schoolStatisticsCourseClassfy,statisticsSchoolresource,schoolStatisticsCourseNubmber,statisticsSchoolexercises} from "@/api";
import echarts from "@/components/echarts";
  export default {
    components:{
      echarts,
    },
    data(){
      return{
        analysereArr:[
          {id:1,name:'资源总数',img:'images/echats1.png',total:'125',tag:'专辑',time:'1665h32min22s',degree:'1545',different:'总时长',different1:'观看次数'},
          {id:2,name:'校内图文资源',img:'images/eacharts2.png',total:'126',tag:'套',time:'1665h32min22s',degree:'1545',different:'资源大小',different1:'观看次数'},
          {id:3,name:'校内试卷统计',img:'images/echarts3.png',total:'127',tag:'套',time:'1665h32min22s',degree:'1545',different:'使用次数',different1:'试卷分类'},
          {id:4,name:'校内题库统计',img:'images/echarts4.png',total:'128',tag:'题',time:'1665h32min22s',degree:'1545',different:'题库使用率',different1:'题目总分类'},
        ],
        statisticsStart:[],
        courseClassfy:[],
        echartscourseFyTitle:'课程数量',
        courseNumbetTotal:{},
        courseClassfyTotal:'',
        exercisesTotal:'',
        schoolExercisesNumber:{},
        courseResourceNumbetTotal:{},
        statisticsSchoolClassfy:[],
        statisticsClassfyTitle:'试题类型',
        startExercises:[],
        statisticsStartTitle:'试题难度'
      }
    },
    methods:{
      //题库分析
      getStatisticsSchoolstart(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId:userInfo.schoolId
        };
        statisticsSchoolstart(data).then(res=>{
          let startTotal={
            oneStart:0,
            twoStart:0,
            threeStart:0,
            fourStart:0,
            fiveStart:0,
          }
          let starArr=[]
          let statisticsSchoolClassfy=[]
          res.data?.forEach(item=>{
            let obj={
              value:item.total_exercises_number,
              type:item.exercises_type
            }
            startTotal.oneStart+=parseInt(item.one_number)
            startTotal.twoStart+=parseInt(item.two_number)
            startTotal.threeStart+=parseInt(item.three_number)
            startTotal.fourStart+=parseInt(item.four_number)
            startTotal.fiveStart+=parseInt(item.five_number)
            statisticsSchoolClassfy.push(obj);
          })
          statisticsSchoolClassfy.forEach((item,index)=>{
            if(item.type=='FB'){
              this.$set(statisticsSchoolClassfy[index],  "name",'填空题');
            }
            if(item.type=='SC'){
              this.$set(statisticsSchoolClassfy[index],  "name",'单选题');
            }
            if(item.type=='MC'){
              this.$set(statisticsSchoolClassfy[index],  "name",'多选题');
            }
            if(item.type=='TF'){
              this.$set(statisticsSchoolClassfy[index],  "name",'判断题');
            }
            if(item.type=='SAQ'){
              this.$set(statisticsSchoolClassfy[index],  "name",'简答题');
            }
          })
          this.statisticsSchoolClassfy=statisticsSchoolClassfy
          starArr.push(startTotal.oneStart)
          starArr.push(startTotal.twoStart)
          starArr.push(startTotal.threeStart)
          starArr.push(startTotal.fourStart)
          starArr.push(startTotal.fiveStart)
          let statName=['一星','二星','三星','四星','五星',]
          let arr=[]
          starArr.forEach(item=>{
            let starObj={}
            starObj.value=item
            arr.push(starObj);
          })
          statName.forEach((item,index)=>{
            this.$set(arr[index],  "name",item);
          })
          let showData = [];
          arr.forEach(d=>{
            let num = d.value;
            if(num>0){
              showData.push(d);
            }
          })
          this.startExercises=showData;
          this.statisticsStart=res.data;
        })
      },
    //  统计学校课程分类数据
      getStatisticsCourseClassfy(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId: userInfo.schoolId
        };
        schoolStatisticsCourseClassfy(data).then(res=>{
          let total=0
          res.data?.forEach(item=>{
            let obj={
              value:item.course_number,
              name:item.course_classify_name
            }
            this.courseClassfy.push(obj);
            total+=parseInt(item.course_number)
          })
          this.courseClassfyTotal=total
        })
      },
    //  获取学校课程
      getSchoolStatisticsCourseNubmber(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId: userInfo.schoolId
        };
        schoolStatisticsCourseNubmber(data).then(res=>{
          this.courseNumbetTotal=res.data[0]
        })
      },
    // 19.3 统计学校习题数据
      getStatisticsSchoolexercises(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId: userInfo.schoolId
        };
        statisticsSchoolexercises(data).then(res=>{
          let total=0
          res.data?.forEach(item=>{
            total+=parseInt(item.exercises_total_number)
            if(item.owen_type=='teacher'){
              this.schoolExercisesNumber=item
            }
          })
          this.exercisesTotal=total
        })
      },
    //  19.5获取学校资源统计
      getstatisticsSchoolresource(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId: userInfo.schoolId
        };
        statisticsSchoolresource(data).then(res=>{
          this.courseResourceNumbetTotal=res.data[0]
        })
      }
    },
    mounted() {
      this.getStatisticsSchoolstart();
      this.getStatisticsCourseClassfy();
      this.getSchoolStatisticsCourseNubmber();
      this.getStatisticsSchoolexercises();
      this.getstatisticsSchoolresource()
    }
  }
</script>
<style scoped>
@import "../../../../public/css/statisticsAnalyse.css";
</style>
