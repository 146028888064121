<template>
  <div>
    <div class="classAdministration">
      <span></span>
      <span>阅卷管理</span>
    </div>
<!--    <div class="testTabFlex">-->
<!--      <p :class="testTabShow==1?'testTabActive':''" @click="changeTest(1)">校内考试</p>-->
<!--      <p :class="testTabShow==2?'testTabActive':''" @click="changeTest(2)">职教高考</p>-->
<!--    </div>-->

      <div class="examinationFlex">
        <el-tabs v-model="testTabShow" @tab-click="changeTest(testTabShow)">
          <el-tab-pane label="班级测试" name="1"></el-tab-pane>
          <el-tab-pane label="职教高考" name="2"></el-tab-pane>
        </el-tabs>
        <div >
          <el-input v-model="examinationTitle" style="margin-right: 12px"  @change="searchClassTask" size="small"></el-input>
          <el-button type="primary" size="mini" @change="searchClassTask">搜索</el-button>
        </div>
      </div>
    <div class="measurementTable" v-if="teacherCheckExaminationArr.length">
      <el-table v-loading="loading" :data="teacherCheckExaminationArr" style="width: 100%;" >
        <el-table-column prop="index" type="index" :index="indexMethod" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="finishedNumber" label="已批阅数">
        </el-table-column>
        <el-table-column prop="unfinishedNumber" label="未批阅数">
        </el-table-column>
        <el-table-column prop="totalScore" label="总分">
        </el-table-column>
        <el-table-column prop="passScore" label="及格分">
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="180">
          <template slot-scope="scope">
            <div class="celerityRead">
              <span @click="goModifyPaper(scope.row)">快速批阅</span>
              <span @click="goChechPaperList(scope.row)">查看详情</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 30px 0">
        <el-pagination
            class="text_center"
            background
            @current-change="handleExamintionChange"
            :current-page.sync="ExamintionPage"
            :page-size="ExamintionSize"
            layout="total,  prev, pager, next, jumper"
            :total="ExamintionTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="!teacherCheckExaminationArr.length" style="width: 100%">
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>
<script>
import {teacherCheckExaminationList} from "@/api";
  export  default {
    data(){
      return{
        loading: false,
        examinationShow: 0,
        amendTab: [
          {ia: 1, name: "全部"},
          {ia: 2, name: "待批阅"},
          {ia: 3, name: "已批阅"},
        ],
        teacherCheckExaminationArr:[],
        ExamintionPage:1,
        ExamintionSize:10,
        ExamintionTotal:0,
        examinationTitle:'',
        testTabShow:'1'
      }
    },
    methods:{
      // 测试管理下,批阅.已批阅
      changeExamination(index) {
        this.examinationShow = index;
      },
      // 快速批阅
      goModifyPaper(item) {
        let routeUrl = this.$router.resolve({
          path: "/modifyPaper",
          query: {
            celerityCheckExam:'celerityCheckExam',
            id:item.id,
            progressType:'NR'
          }
        });
        window.open(routeUrl.href, "/modifyPaper");
      },
      // 测试或者作业查看详情
      goChechPaperList(item) {
        let routeUrl = this.$router.resolve({
          path: "/checkPaperList",
          query:{
            id:item.id,
            type:'exam'
          }
        });
        sessionStorage.setItem('examDetail',JSON.stringify(item) )

        window.open(routeUrl.href);
      },
    //  获取教师批卷列表
      getTeacherCheckExaminationList(){
        this.loading = true;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data={
          schoolId:userInfo.schoolId,
          teacherId:userInfo.id,
          page:this.ExamintionPage,
          size:this.ExamintionSize,
        };
        if(this.examinationTitle){
          data['name']=this.examinationTitle
        }
        if(this.testTabShow==1){
          data.testPaperType=0
        }else{
          data.testPaperType=1
        }
        teacherCheckExaminationList(data).then(res=>{
          this.teacherCheckExaminationArr=res.data.records
          this.ExamintionTotal=parseInt(res.data.total)
          this.loading = false;
        })
      },
      indexMethod(index) {
        return index + (this.ExamintionPage * this.ExamintionSize) -9 ;
      },
      //  分页
      handleExamintionChange(ExamintionPage){
        this.ExamintionPage=ExamintionPage;
        this.getTeacherCheckExaminationList()
      },
      //搜索
      searchClassTask(){
        this.getTeacherCheckExaminationList()
      },
    //  改变阅卷tab
      changeTest(index){
        this.testTabShow=index;
        this.getTeacherCheckExaminationList()
      }
    },
    mounted() {
      this.getTeacherCheckExaminationList()
    }
  }
</script>
