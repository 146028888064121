<template>
  <div>
    <template v-if="!certificateShow">
      <div class="classAdministration">
        <span></span>
        <span>证书管理</span>
      </div>
      <div class="certificateInputFlex">
        <el-cascader
            v-model="value"
            :options="options" placeholder="年级"></el-cascader>
        <el-input class="certificateInput" v-model="input" placeholder="请输入内容"></el-input>
        <el-button type="primary">搜索</el-button>
      </div>
      <div>
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              prop="number"
              label="学号"
              width="180">
          </el-table-column>
          <el-table-column
              prop="name"
              label="姓名"
              width="180">
          </el-table-column>
          <el-table-column
              prop="identity"
              label="身份证">
          </el-table-column>
          <el-table-column
              prop="subjects"
              label="科目">
          </el-table-column>
          <el-table-column
              prop="phone"
              label="联系方式">
          </el-table-column>
          <el-table-column
              prop="class"
              label="班级">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="120">
            <template slot-scope="scope">
              <el-button type="primary" plain @click="changeCertificate">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template v-if="certificateShow">
      <div>
        <div class="certificateTitle">学生详情</div>
        <div class="certificatestdDetailFlex">
          <div>
            <div>学生姓名：庞博</div>
            <div>
              <p>班级：数媒一班</p>
              <p>学生学号：2020-1001</p>
            </div>
            <div>
              <p>科目名称：互联网营销师</p>
              <p>级别：初级</p>
            </div>
            <div>
              <p>身份证号：372747199904121448</p>
              <p>联系电话：19925406480</p>
            </div>
          </div>
          <img src="/images/zanwuAvatar.png"/>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  export default {
    data(){
      return{
        certificateShow:true,
        value: [],
        options: [{
          value: 'zhinan',
          label: '指南',
          children: [{
            value: 'shejiyuanze',
            label: '设计原则',
          }, ]
        }, ],
        input:"",
        tableData: [{
          number: '2016-02',
          name: '王小虎',
          identity: '371522565214578',
          subjects:"平面设计师",
          phone:"15423124789",
          class:"平面设计一班"
        }, {
          number: '2016-02',
          name: '王小虎',
          identity: '371522565214578',
          subjects:"平面设计师",
          phone:"15423124789",
          class:"平面设计一班"
        }, ]
      }
    },
    methods:{
      changeCertificate(){
        this.certificateShow=true
      }
    },

  }
</script>
<style>
  .certificateInputFlex{
    display: flex;
    align-items: center;
    width: 60%;
  }
  .certificateInput{
    width: 40%;
    margin: 0 20px;
  }
  .classAdministration {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .classAdministration > span:first-child {
    display: inline-block;
    background: #ff7000;
    width: 4px;
    height: 16px;
    margin-right: 8px;
  }
  .classAdministration>span:last-child{
    font-size: 16px;
    font-weight: bold;
  }
  .certificatestdDetailFlex{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .certificatestdDetailFlex>img{
    width: 200px;
  }
  .certificatestdDetailFlex>div>div{
    display: flex;
    align-items: center;
    margin: 32px 0;
    color: #666666;
  }
  .certificateTitle{
    font-size: 20px;
    font-weight: bold;
  }
  .certificatestdDetailFlex>div>div>p:first-child{
    width: 340px;
  }
</style>