<template>
  <div>
    <div>
<!--      <el-steps  :active="questionStep" finish-status="success">-->
<!--        <el-step title="上传试题"></el-step>-->
<!--        <el-step title="题库查重"></el-step>-->
<!--        <el-step title="导入试题"></el-step>-->
<!--      </el-steps>-->
    </div>
    <div class="classAdministration">
      <span></span>
      <span>批量导入</span>
    </div>
    <div>
      <div class="cooseZhangejie">
        <div>
          <template>
            <el-select v-model="courseValue" placeholder="请选择课程" @change="changeCourse()" size="small">
              <el-option
                  v-for="item in schoolCourseLIstArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.courseItemList">
              </el-option>
            </el-select>
          </template>
        </div>
        <div>
          <el-cascader
              size="small"
              v-model="courserZ"
              :placeholder="'请选择章节'"
              :options="courserJ"
              :props="props"
              @change="changeCourseZj()"
          >
          </el-cascader>
        </div>
      </div>
      <div v-if="questionStep==1">
        <div class="batchQuestion">
          <div class="uploadCneter">
            <el-upload
                class="upload-demo"
                :http-request="uploadFile"
                action="/frontApi/baseFile/upload"
            >
              <p>
                <i class="el-icon-upload el-icon--right"></i>
                <span style="display: inline-block;margin-left: 10px">{{repetitionExercises.length?'重新上传试题':'选择文件上传试题'}}</span>
              </p>
            </el-upload>
          </div>
<!--          上传重复习题显示区域-->
          <div class="repeatK" v-if="repetitionExercises.length">
            <div>习题重复展示</div>
            <template>
              <el-table
                  :data="repetitionExercises"
                  height="250"
                  style="width: 100%">
                <el-table-column
                    type="index"
                    :index="indexMethod">
                </el-table-column>
                <el-table-column
                    prop="date"
                    label="题目">
                  <template slot-scope="scope">
                    <span>{{scope.row}}</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
          <div @click="downloadTemplate" class="uploadCneter" >
            <img src="images/xiazai.png" >
          </div>
        </div>
        <div class="batchExplain">
          <p>批量导入说明</p>
          <p>1.支持批量导入题型：单选题、多选题、不定项选择题、判断题、填空题</p>
          <p>2.建议按多次分批导入，导入前建议编辑好分类，方便题库管理</p>
          <p>3.请使用微软的Office编辑，不要使用wps</p>
        </div>
      </div>
      <div v-if="questionStep==2">
        11
      </div>
    </div>
  </div>
</template>
<script>
import {teacherBatchupload, courseDifficulty, courseList, teacherUpLoad} from "@/api";
  export default {
    data(){
      return{
        questionStep:1,
        courseValue:'',
        schoolCourseLIstArr:[],
        courserZ:[],
        courserJ:[],
        props: {
          value: 'id',
          label: 'name',
        },
        repetitionExercises:[],
      }
    },
    methods:{
      //  获取课程列表
      getCourseAdministrationList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          page: 1,
          size: 100
        }
        if (userInfo.adminData == 1) {
          data['schoolId'] = userInfo.schoolId
        } else {
          data['ownerId'] = userInfo.id
        }
        courseList(data).then(res => {
          this.schoolCourseLIstArr = res.data.records;
        })
      },
      //  选择课程
      changeCourse(id,name) {
        //  获取课程详情树形结构
        let courseValue = this.courseValue
        const listToTree = (listArr, pid) => {
          let tree = [];
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseValue, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
          return tree;
        }
        this.courserJ = listToTree(courseValue, '0');
      },
      //  获取章节列表
      changeCourseZj(){
      },
      //  下载excel模板
      downloadTemplate(){
        let data={
          categoryCode:'exercises-template-download-url'
        }
        courseDifficulty(data).then(res => {
          window.location.href =res.data.records[0].itemCode
        })
      },
      //上传文件
      uploadFile(res){
        if(!this.courseValue.length){
          this.$message.error("请选择课程");
          return;
        }
        if(!this.courserZ.length){
          this.$message.error("请选择课程章节");
          return;
        }
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
          var forms = new FormData()
          forms.append('file',res.file)
          forms.append('bizType','exercises')
          forms.append('aliVideoOssData',0);
          forms.append('schoolId',userInfo.schoolId)
          teacherUpLoad(forms).then(res=>{
            if(res.code==0){
              let data={
                platformCourseId:this.courseValue[0].platformCourseId,
                platformCourseItemId:this.courserZ[1],
                fileId:res.data.id
              }
              teacherBatchupload(data).then(res=>{
                if(res.code==0){
                  this.$message({
                    message: '上传成功',
                    type: 'success'
                  });
                  this.repetitionExercises=[]
                }else if(res.code==100129){
                  let arr=res.msg.slice(1,-1);
                  this.repetitionExercises=arr.split(',')
                }else{
                  this.$message.error('解析失败')
                }
              })
            }else{
              this.$message.error(res.msg)
            }
          })
      },
      indexMethod(index) {
        return index+1+'.';
      }
    },
    mounted() {
      this.getCourseAdministrationList();
    }
  }
</script>
<style scoped>
@import "../../../../public/css/batchChannel.css";
</style>
