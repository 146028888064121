<template>
  <div>
    <div :id="id" :style="{width: '100%', height: '300px'}"></div>
  </div>
</template>
<script>
var echarts = require('echarts');
// import echarts from "echarts"
export default {
  name: 'echarts',
  props: {
    data: {
      required: true,
      default() {
        return []
      },
    },
    id: {
      required: true,
      default() {
        return ''
      },
    },
    echartsTitle: {
      type: String,
      required: true,
    },
    legendShow:{
      type: Boolean,
      required: true,
    },

  },
  data() {
    return {
      charts: '',
    }
  },
  watch: {
    data(item) {
      this.setOption();
    }
  },
  methods: {
    goToUserCenter() {
      this.$router.replace('/userCenterIndex')
    },
    goCourseCenter() {
      this.$router.push('prepareCourseCenter')
    },
    drawPie(id) {
      this.charts = echarts.init(document.getElementById(id))
      this.setOption();
    },
    setOption() {
      this.charts.setOption({
        title: {
          text: this.echartsTitle,//主标题
          // subtext: '我是副标题可有可无',//副标题
          x: 'center',//x轴方向对齐方式
          top: 10,//标题栏的高度，值越大，距离顶部的距离越大
          textStyle: {
            color: '#000'  //标题栏字体的颜色
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: '',
          right: '80%',
          // top:'0%',
          left:'0%',
          show:this.legendShow
        },
        series: [
          {
            name: '访问来源', 	 //饼图中内容介绍
            type: 'pie',   		//统计表的类型
            radius: '60%',   	//饼图的弧度，值越小生成的饼状图越小
            center: ['50%', '55%'], 			//饼图生成后的位置
            data: this.data,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              },
              normal: {
                //自定义饼状图每一份的颜色
                color: function (params) {
                  var colorList = [
                    '#44C0C1', '#188df0', '#FFFF00', '#FF8C00', '#FF0000', '#FE8463','#49B151','#b521f3','#f321b3','#f3216f','#f3cf21',
                  ];
                  return colorList[params.dataIndex]
                }
              }
            }
          }
        ]
      })
    }

  },
  mounted() {
    this.drawPie(this.id)

  }
}
</script>
<!--goToUserCenter() {-->
<!--this.$router.replace('/userCenterIndex')-->
<!--},-->
<!--goCourseCenter() {-->
<!--this.$router.push('prepareCourseCenter')-->
<!--},-->
