<template>
  <div>
    <div class="classAdministration">
      <span></span>
      <span>作业管理</span>
    </div>
    <div class="examinationFlexs">
      <div class="examinationFlex">
        <div>
          <span>标题：</span>
          <el-input size="small"  v-model="examinationData.examinationTitle" @change="searchClassTask"></el-input>
        </div>
        <div>
          <span>班级：</span>
          <el-input size="small"  v-model="examinationData.examinationClass" @change="searchClassTask"></el-input>
        </div>
        <div>
          <span>课程：</span>
          <el-input size="small" v-model="examinationData.examinationCourse" @change="searchClassTask"></el-input>
        </div>
        <el-button type="primary" size="mini"  @click="searchClassTask()" style="margin-left: 10px">搜索</el-button >
        <el-button  type="primary" size="mini" @click="goWorkPublish">
          发布作业
        </el-button >
      </div>
    </div>
    <div class="measurementTable" v-if="workList.length">
      <el-table v-loading="loading" :data="workList" style="width: 100%" >
        <el-table-column prop="index"  type="index" :index="indexMethod" label="序号">
        </el-table-column>
        <el-table-column prop="name" label="标题">
        </el-table-column>
        <el-table-column prop="className" label="班级">
        </el-table-column>
        <el-table-column prop="platformCourseName" label="课程">
        </el-table-column>
        <el-table-column  label="完成情况">
          <template slot-scope="scope">
            <p>{{ scope.row.finisheNumber }}/{{parseInt(scope.row.finisheNumber)+parseInt(scope.row.unfinishedNumber)}}</p>
          </template>
        </el-table-column>
        <el-table-column prop="operation" label="操作" width="180">
          <template slot-scope="scope">
            <div class="celerityRead">
              <span @click="goModifyPaper(scope.row)">快速批阅</span>
              <span @click="goChechPaperList(scope.row)">查看详情</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:50px" >
        <el-pagination
            class="text_center"
            background
            @current-change="handleWorkList"
            :current-page.sync="workPage"
            :page-size="workSize"
            layout="total,  prev, pager, next,jumper"
            :total="workTotal"
        >
        </el-pagination>
      </div>
    </div>
    <div v-if="!workList.length" style="width: 100%">
      <el-empty :image-size="200"></el-empty>
    </div>
  </div>
</template>
<script>
import {teacherClassTask} from "@/api";
export  default {
  data(){
    return{
      loading: false,
      amendTab: [
        {ia: 1, name: "全部"},
        {ia: 2, name: "待批阅"},
        {ia: 3, name: "已批阅"},
      ],
      examinationData:{
        examinationTitle:"",
        examinationClass:"",
        examinationCourse:""
      },
      workList:[],
      workPage:1,
      workSize:10,
      workTotal:0
    }
  },
  methods:{
    indexMethod(index) {
      return index + (this.workPage * this.workSize) -9 ;
    },
    //搜索班级作业列表
    searchClassTask(){
      this.getClassTaskList()
    },
    // 快速批阅
    goModifyPaper(item) {
      let routeUrl = this.$router.resolve({
        path: "/modifyPaper", //新页面地址
        query: {
          celerityCheckWork:'celerityCheckWork',
          id:item.id,
          title:item.name,
          progressType:'NR'
        }
      });
      window.open(routeUrl.href, "/modifyPaper");
    },
    // 作业查看详情
    goChechPaperList(item) {
      let routeUrl = this.$router.resolve({
        path: "/checkPaperList",
        query:{
          id:item.id,
          type:'work'
        }
      });
      sessionStorage.setItem('workDetail',JSON.stringify(item) )
      window.open(routeUrl.href);
    },
  //  发布作业
    goWorkPublish(){
      let routeUrl = this.$router.resolve({
        path: "/workPublish"
      });
      window.open(routeUrl.href, '_blank');
    },
  //  获取作业列表
    getClassTaskList(){
      this.loading = true;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        page:this.workPage,
        size:this.workSize,
      }

      if(this.examinationData.examinationTitle){
        data['name']=this.examinationData.examinationTitle
      }
      if(this.examinationData.examinationClass){
        data['className']=this.examinationData.examinationClass
      }
      if(this.examinationData.examinationCourse){
        data['platformCourseName']=this.examinationData.examinationCourse
      }
      teacherClassTask(data).then(res=>{
        if(res.code==0){
          res.data.records.forEach((item,index)=>{
            let totalNumber= parseInt(item.unfinishedNumber+item.finisheNumber)
            this.$set(res.data.records[index],  "totalNumber",totalNumber);
          })
          this.workList=res.data.records;
          this.workTotal=parseInt(res.data.total)
          this.loading = false;
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  分页
    handleWorkList: function (workPage) {
      this.workPage = workPage;
      this.getClassTaskList()
    },
  },
  mounted() {
    this.getClassTaskList()
  }
}
</script>
<style>
  .examinationElInput{
    width: 68% !important;
  }
</style>
