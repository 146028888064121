<template>
  <div>
    <div class="preventQustiuonFelx">
      <div v-for="(item,index) in questionTab" :key="index" class="preventQustiuonFelxDiv" @click="changeQuestionTab(index)">
        <img :src="item.img"/>
        <p>{{item.name}}</p>
      </div>
    </div>
<!--    试卷内容-->
    <div >
      <div v-if="!organizationExam">
        <div class="examinationTabFlex">
<!--          <p :class="examinationTabShow==1?'testTabActive':''" @click="changeExaminationTab(1)">校内考试</p>-->
<!--          <p :class="examinationTabShow==2?'testTabActive':''" @click="changeExaminationTab(2)">职教高考</p>-->
          <el-tabs v-model="examinationTabShow" @tab-click="changeExaminationTab(examinationTabShow)">
            <el-tab-pane label="班级测试" name="1"></el-tab-pane>
            <el-tab-pane label="职教高考" name="2"></el-tab-pane>
          </el-tabs>
          <div>
            <el-button type="primary" size="small" v-if="questionContentShow==0"  @click="goExaminationList(examinationTabShow)">新建试卷</el-button>
          </div>
        </div>
        <div class="QuestionsManagementBorder">
          <div class="examinationStates" v-if="questionContentShow==1">
            <span v-for="(item,index) in examinationState" :key="index" @click="changeExamination(index)" class="stateBtn" :class="examinationShow==index?'stateActive':''">{{item.name}}</span>
            <div v-if="!examinationState.length" style="width: 100%">
              <el-empty :image-size="200"></el-empty>
            </div>
          </div>
        </div>
        <template v-if="questionContentShow===0">
          <div class="examinationQuestionFlex examinationQuestionBb" v-for="(item,index) in examinationListArr" :key="index">
            <div class="examinationQuestionFlex">
              <img :src="item.logo?item.logo:'images/zanwuImgs.png'" class="examinationImg"/>
              <div class="examinationTitle">
                <p>{{item.name}}</p>
                <p>关键字:{{item.keyWords?item.keyWords:''}} </p>
                <p>描述:{{item.papersDescribe}}</p>
              </div>
            </div>
            <div>
              <div class="examinationFlexBtn deletePublishBtn" >
                <el-button type="primary" size="small" plain @click="amendChangeQustion(item)">管理试题</el-button>
                <el-button type="primary" size="small" plain @click="changeDeleteExamination(item)">删除</el-button>
                <el-button type="primary" size="small"  @click="goPublish(item)">发布</el-button>
              </div>
            </div>
          </div>
          <div v-if="!examinationListArr.length" style="width: 100%">
            <el-empty :image-size="200"></el-empty>
          </div>
          <div style="margin: 30px 0">
            <el-pagination
                class="text_center"
                background
                @current-change="handleCourseChange"
                :current-page.sync="coursePage"
                :page-size="courseSize"
                layout="total,  prev, pager, next, jumper"
                :total="courseTotal"
            >
            </el-pagination>
          </div>
        </template>
        <template >
          <div v-if="questionContentShow===1||questionContentShow===2" class="examinationQuestionFlex examinationQuestionBb" v-for="(item,index) in pigeonholeExamitionList" :key="index">
            <div class="examinationQuestionFlex">
              <img :src="item.logo?item.logo:'images/zanwuImgs.png'" class="examinationImg"/>
              <div class="examinationTitle">
                <p>{{item.name}}</p>
                <p>考试时长:{{parseInt(item.answerTime/60) }}分钟 </p>
                <p>总分:{{item.totalScore}}</p>
              </div>
            </div>
            <div>
              <div class="examinationFlexBtn previewExam" v-if="questionContentShow===1">
                <p v-for="(item1,index1) in questionBtn" :key="index1" @click="changeQustion(index1,item)">{{item1.name}}</p>
              </div>
              <div class="examinationFlexBtn previewExam" v-if="questionContentShow===2">
                <p @click="goExaminationDetail(item)">考试详情</p>
              </div>
            </div>
          </div>
          <div style="margin: 30px 0" v-if="questionContentShow===1||questionContentShow===2">
            <el-pagination
                class="text_center"
                background
                @current-change="handleOrganizationChange"
                :current-page.sync="organizationPage"
                :page-size="organizationSize"
                layout="total,  prev, pager, next, jumper"
                :total="organizationTotal"
            >
            </el-pagination>
          </div>
        </template>

      </div>
    </div>
<!--    组织发布-->
    <div v-if="organizationExam">
<!--      <div>-->
<!--        <p class="QuestionNavigation">-->
<!--          <span>授课中心 ></span>-->
<!--          <span>试卷管理 ></span>-->
<!--          <span>组织考试 ></span>-->
<!--          <span>发布考试</span>-->
<!--        </p>-->
<!--      </div>-->
      <div class="questioncontFlex">
        <div class="questionwhidth">
          <div class="">
            <span>考试名称：</span>
            <el-input class="questionAnswerName" placeholder="请输入考试名称" v-model="examinationInfo.createExaminationTitle"></el-input>
          </div>
          <div>
            <span>考生分配：</span>
            <el-select v-model="examinationInfo.candidateStdValue" multiple placeholder="请选择">
              <el-option
                  v-for="item in classAdministrationData"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div>
            <span>阅卷老师：</span>
            <el-select v-model="examinationInfo.examinationTeacherValue" multiple placeholder="请选择">
              <el-option
                  v-for="item in teacherList"
                  :key="item.id"
                  :label="item.name?item.name:item.account"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>
          <div class="publishFlex">
            <span>分数及格线：</span>
            <el-input class="answerTime" placeholder="分数及格线" v-model="examinationInfo.passScore"></el-input>
            <span>分</span>
          </div>
          <div class="publishFlex">
            <span>答题时长：</span>
            <el-input class="answerTime" placeholder="答题时长" v-model="examinationInfo.examinationTimeDuration"></el-input>
            <span>分钟</span>
          </div>
          <div class="quetiontIMErADIO">
            <span>有效期限：</span>
            <el-date-picker
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="examinationInfo.examinationTime"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="examinationName">
          <p>试卷模板名称</p>
          <p>{{examPapersTitle?examPapersTitle:examinationRouterData.examPapersTitle}}</p>
          <p>
            <span @click="publishExation">发布</span>
            <span @click="returnExaminationList">返回</span>
          </p>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="previewQuestionShow" :show-close="true">
      <div class="previewQuestion" >
        <div>
          <img src="images/eLogoSvg.png" style="width: 200px"/>
<!--          <p >育工匠 融校企 促就业 创未来</p>-->
        </div>
        <div>
          <p class="quetionTitlePreview">{{examinationDetail.name}}</p>
          <p>考试说明</p>
          <div>
            <p>
              <span>考试名称</span>
              <span>{{examinationDetail.name}}</span>
            </p>
            <p>
              <span>考试限时</span>
              <span>{{parseInt(examinationDetail.answerTime/60) }}分钟</span>
            </p>
            <p>
              <span>考生分配</span>
              <el-select v-model='examinationDetailName' >
                <el-option
                    v-for="item in examinationDetail.classList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name">
                </el-option>
              </el-select>
            </p>
            <p>如果考试异常中断，请退出并及时按同样步骤进入，继续考试</p>
          </div>
<!--          <p class="enterQuestion">进入考试</p>-->
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  examinationList,
  deleteExamination,
  classList,
  schoolTeacherList,
  publishExamition,
  organizationExamitionList,
  cancelExamination,
  removeStudent
} from "@/api";
import {selfDeletedStudyNotes} from "@/api/user";
export default {
  data(){
    return{
      questionBtn:[
        {id:1,name:'预览考试'},
        // {id:4,name:'考试详情'},
        {id:4,name:'取消考试'},
      ],
      previewQuestionShow:false,
      questionTab:[
        {id:1,name:'试卷总览',img:'images/organization.png'},
        {id:2,name:'组织考试',img:'images/exam.png'},
        {id:3,name:'归档考试',img:'images/pigeonhole.png'},
      ],
      questionContentShow:0,
      organizationExam:false,
      examinationState:[
        {id:1,name:"全部"},
        {id:2,name:"未开始"},
        {id:3,name:"进行中"},
      ],
      examinationShow:0,
      examinationListArr:[],
      coursePage:1,
      courseSize:10,
      courseTotal:0,
      classAdministrationData:[],
      teacherList:[],
      examPapersId:'',
      examPapersLogo:'',
      examPapersTitle:"",
      pigeonholeExamitionList:[],
      examinationDetail:{},
      examinationDetailName:'',
      examinationInfo:{
        candidateStdValue:'',
        examinationTeacherValue:'',
        createExaminationTitle:'',
        examinationTimeDuration:'',
        examinationTime:'',
        passScore:''
      },
      organizationPage:1,
      organizationSize:10,
      organizationTotal:0,
      examinationRouterData:{},
      examinationTabShow:'1',
      addNewShow:false,
      subjectType:''
    }
  },
  methods:{
    //改变试卷状态
    changeExamination(index){
      this.examinationShow=index;
      this.getExamationList()
    },
  //  预览考试
    changeQustion(index,item){
      if(index==0){
        this.previewQuestionShow=true
        this.examinationDetail=item;
        this.examinationDetailName=item.classList[0].name
      }
      // if(index==1){
      //   let routeUrl = this.$router.resolve({
      //     path: 'examinationAnalyse',
      //     query: {
      //       papersTestCenterId : item.id,
      //       examinationScore:item.totalScore,
      //       name:item.name
      //     }
      //   });
      //   window.open(routeUrl.href, '_blank');
      // };
      //取消考试
      if(index==1){
        this.$confirm("确认取消?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
            .then(() => {
              let data={
                id:item.id,
                mState:'2'
              }
              cancelExamination(data).then(res => {
                if (res.code == 0) {
                  this.$message({
                    message: "取消成功",
                    type: "success",
                    duration: 2000
                  });
                  this.getExamationList()
                }
              })
            })
            .catch(() => {});
      }
    },
    //去考试详情
    goExaminationDetail(item){
      let routeUrl = this.$router.resolve({
        path: 'examinationAnalyse',
        query: {
          papersTestCenterId : item.id,
          examinationScore:item.totalScore,
          name:item.name
        }
      });
      window.open(routeUrl.href, '_blank');
    },
  //  修改试卷模板或者模板详情
    amendChangeQustion(item){
      if(this.examinationTabShow==1){
        let routeUrl = this.$router.resolve({
          path: "/addExaminationPaper",
          query:{amendexAmination:JSON.stringify(item)}
        });
        window.open(routeUrl.href);
      }else{
        let routeUrl = this.$router.resolve({
          path: "/addExaminationPaper",
          query:{amendexAmination:JSON.stringify(item),vocational:1}
        });
        window.open(routeUrl.href);
      }

    },
  //  tab
    changeQuestionTab(index){
      this.questionContentShow=index;
      if(index===1||index===2){
        this.getExamationList()
      }
      this.organizationExam=false;
    },
  //  获取试卷列表
    getList(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        page:this.coursePage,
        size:this.courseSize,
        schoolId:userInfo.schoolId,
        teacherId:userInfo.id,
        privateTeacherData:1
      }
      if(this.examinationTabShow==1){
        data['testPaperType']=0
      }else{
        data['testPaperType']=1
      }
      examinationList(data).then(res=>{
        if(res.code=='0'){
          this.examinationListArr=res.data.records;
          this.courseTotal=JSON.parse(res.data.total);
        }
        if(res.code=='100128'){
          this.$message('您没有操作权限');
          this.addNewShow=true
        }
      })
    },
    //去新建试卷
    goExaminationList(index){
      if(this.addNewShow){
        return;
      }else if(index==2&&!this.addNewShow){
        let routeUrl = this.$router.resolve({
          path: "/addExaminationPaper",
          query:{vocational:1}
        });
        window.open(routeUrl.href);
      }else{
        let routeUrl = this.$router.resolve({
          path: "/addExaminationPaper",
        });
        window.open(routeUrl.href);
      }
    },
  //  发布
    goPublish(item){
      this.organizationExam=true;
      this.getClassList();
      this.getTeacherList();
      this.examPapersId=item.id
      this.examPapersLogo=item.logo;
      this.examPapersTitle=item.name
      this.subjectType=item.subjectType
    },
  //返回
    returnExaminationList(){
      this.organizationExam=false;
      this.examinationInfo={}
    },
    //  分页
    handleCourseChange(coursePage){
      this.coursePage=coursePage;
      this.getList()
    },
  //  组织考试分页
    handleOrganizationChange(organizationPage ){
      this.organizationPage=organizationPage;
      this.getExamationList()
    },
  //  删除试卷模板
    changeDeleteExamination(item){
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // let data={
        //   examPapersIds:item.id
        // }
        let data=[item.id]
        deleteExamination(data).then(res=>{
          if(res.code=="0"){
            this.getList()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          }else{
            this.$message.error('失败，请重试');
          }
        })

      }).catch(() => {

      });
    },
    //  获取班级列表
    getClassList() {
      this.loading = true;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        schoolId: userInfo.schoolId,
        page:1,
        size:100
      }
      classList(data).then(res => {
        if(res.data.records){
          this.classAdministrationData = res.data.records;
        }
      })
    },
    //获取教师列表
    getTeacherList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        page: 1,
        size: 150,
        schoolId:userInfo.schoolId
      }
      schoolTeacherList(data).then(res => {
        this.teacherList = res.data.records;
      })
    },
  //  发布考试
    publishExation(){
      if(!this.examinationInfo.createExaminationTitle){
        this.$message({
          message: '标题不可为空',
          type: 'warning'
        });return
      }
      if(!this.examinationInfo.candidateStdValue.length){
        this.$message({
          message: '请选择班级',
          type: 'warning'
        });return
      }
      if(!this.examinationInfo.examinationTeacherValue.length){
        this.$message({
          message: '请选择阅卷老师',
          type: 'warning'
        });return
      }
      if(!this.examinationInfo.examinationTime.length){
        this.$message({
          message: '请选择时间',
          type: 'warning'
        });return
      }
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        schoolId:userInfo.schoolId,
        examPapersId:this.examPapersId?this.examPapersId:this.examinationRouterData.examPapersId,
        logo:this.examPapersLogo?this.examPapersLogo:this.examinationRouterData.logo,
        name:this.examinationInfo.createExaminationTitle?this.examinationInfo.createExaminationTitle:this.examinationRouterData.createExaminationTitle,
        examsTeacherIds:this.examinationInfo.examinationTeacherValue.join(','),
        classIds:this.examinationInfo.candidateStdValue.join(','),
        effectiveStartTime:this.examinationInfo.examinationTime[0],
        effectiveEndTime:this.examinationInfo.examinationTime[1],
        answerTime:this.examinationInfo.examinationTimeDuration*60,
        passScore:this.examinationInfo.passScore,
        subjectType:this.subjectType
      }
      if(this.examinationTabShow==1){
        data.testPaperType=0
      }else{
        data.testPaperType=1
      }
      publishExamition(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: '发布成功',
            type: 'success'
          });
          this.organizationExam=false;
          sessionStorage.removeItem("publishExaminationData");
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  获取考场列表
    getExamationList(){
      let data={
        page:this.organizationPage,
        size:this.organizationSize,
      };
      if(this.examinationTabShow==1){
        data['testPaperType']=0
      }else{
        data['testPaperType']=1
      }
      if(this.examinationShow==1&&this.questionContentShow==1){
        data['queryRunningType']=0
      }
      if(this.examinationShow==2&&this.questionContentShow==1){
        data['queryRunningType']=1
      }
      if(this.questionContentShow==1){
        data['queryIsFile']=0
      }
      if(this.questionContentShow==2){
        data['queryIsFile']=1
      }
      organizationExamitionList(data).then(res=>{
        this.pigeonholeExamitionList=res.data.records;
        this.organizationTotal=JSON.parse(res.data.total)
      })
    },
  //  改变试卷总览tab
    changeExaminationTab(index){
      this.examinationTabShow=index;
      if(this.questionContentShow===1||this.questionContentShow===2){
        this.getExamationList()
      }else{
        this.getList()
      }
    }
  },
  mounted() {
    this.getList()
    let publishExaminationData=sessionStorage.getItem('publishExaminationData');
    if(publishExaminationData){
      this.organizationExam=true;
      this.examinationRouterData=JSON.parse(publishExaminationData)
      this.examPapersTitle=publishExaminationData.examPapersTitle
      this.getClassList();
      this.getTeacherList();
      // sessionStorage.removeItem("publishExaminationData")
    }
    if(this.$route.query.questionContentShow&&this.$route.query.questionContentShow==2){
      this.questionContentShow=this.$route.query.questionContentShow;
      this.changeQuestionTab(2);
      this.changeExaminationTab('2')
    }

  }
}
</script>
<style scoped>
@import "../../../../public/css/examinationAdministration.css";
</style>
