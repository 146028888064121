<template>
  <div class="classCenterBg">
    <div>
      <div class="classCenterContent">
        <div class="classcenterFlex">
          <div>
            <img :src="userInfo.logo?userInfo.logo:'images/tacitlyAvatar.png'" alt="" class="userLogo"/>
            <div>
              <h3>您好，{{userInfo.name?userInfo.name:userInfo.account}}</h3>
              <p class="zhufuyu">祝您开启美好的一天~~</p>
            </div>
          </div>
          <div class="calssArrFlex">
            <div class="classKuaijie" v-for="(item, index) in classArr" :key="index" @click="changeSpeediness(index)" >
              <img :src="item.img" alt=""/>
              <p @mouseenter="changeMousOver(index)" :class="[item.class]">{{ item.name }}</p>
            </div>
          </div>
        </div>
        <div class="classCenterBig">
          <!-- 左侧边栏 -->
          <div style="width: 18%;height:764px">
            <el-row class="tac">
              <el-col>
                <el-menu
                    :default-active="activeShow"
                    class="el-menu-vertical-demo"
                    @select="selectOpen"
                    style="box-shadow: 0px 0px 5px 0px rgba(145, 145, 145, 0.36);border-radius: 4px;"
                >
                  <el-menu-item index="1">
                    <img src="images/icon1.png" alt="" class="iconMargin"/>
                    <span slot="title">统计分析</span>
                  </el-menu-item>
                  <el-menu-item index="2">
                    <img src="images/icon7.png" alt="" class="iconMargin"/>
                    <span slot="title">授课管理</span>
                  </el-menu-item>
                  <el-menu-item index="3">
                    <img src="images/icon8.png" alt="" class="iconMargin"/>
                    <span slot="title">阅卷管理</span>
                  </el-menu-item>
                  <el-menu-item index="4">
                    <img src="images/icon9.png" alt="" class="iconMargin"/>
                    <span slot="title">作业管理</span>
                  </el-menu-item>
                  <el-menu-item index="5">
                    <img src="images/icon10.png" alt="" class="iconMargin"/>
                    <span slot="title">学生管理</span>
                  </el-menu-item>
                  <el-menu-item index="6">
                    <img src="images/icon2.png" alt="" class="iconMargin"/>
                    <span slot="title">班级管理</span>
                  </el-menu-item>
                  <el-menu-item index="7" v-if="userInfo.adminData==1">
                    <img src="images/icon3.png" alt="" class="iconMargin"/>
                    <span slot="title">教师管理</span>
                  </el-menu-item>
                  <el-submenu index="8">
                    <template slot="title">
                      <img src="images/icon4.png" alt="" class="iconMargin"/>
                      <span>资源管理</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="8-1">课程管理</el-menu-item>
                      <el-menu-item index="8-2">文件管理</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-submenu index="9">
                    <template slot="title">
                      <img src="images/icon5.png" alt="" class="iconMargin"/>
                      <span>题库管理</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item index="9-1">试题列表</el-menu-item>
                      <el-menu-item index="9-2">批量导入</el-menu-item>
                      <el-menu-item index="9-3">试卷管理</el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item index="10">
                    <img src="images/icon6.png" alt="" class="iconMargin"/>
                    <span slot="title">账号管理</span>
                  </el-menu-item>
<!--                  <el-menu-item index="11">-->
<!--                    <img src="images/icon6.png" alt="" class="iconMargin"/>-->
<!--                    <span slot="title">证书管理</span>-->
<!--                  </el-menu-item>-->
                </el-menu>
              </el-col>
            </el-row>
          </div>
          <!-- 右侧内容 -->
          <div class="instructionBg">
            <div>
              <component :is="isComponent"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import courseAdministrator from './components/courseAdministrator'
import testAdministrator from './components/testAdministrator'
import classAdministrator from './components/classAdministrator'
import teacherAdministrator from './components/teacherAdministrator'
import studentAdministrator from './components/studentAdministrator'
import resourceCourseAdministrator from './components/resourceCourseAdministrator'
import documentAdministrator from './components/documentAdministrator'
import questionsList from './components/questionsList'
import examinationAdministration from './components/examinationAdministration'
import setAccount from './components/setAccount'
import batchChannel from './components/batchChannel'
import workAdministrator from './components/workAdministrator'
import statisticsAnalyse from './components/statisticsAnalyse'
import certificateManage from './components/certificateManage'
export default {
  components: {
    courseAdministrator,
    testAdministrator,
    classAdministrator,
    teacherAdministrator,
    studentAdministrator,
    resourceCourseAdministrator,
    documentAdministrator,
    questionsList,
    examinationAdministration,
    setAccount,
    batchChannel,
    workAdministrator,
    statisticsAnalyse,
    certificateManage
  },
  data() {
    return {
      activeShow:'1',
      isComponent: '',
      classArr: [
        {id: 1, name: "统计分析", img: "images/statistics.png",class:''},
        {id: 2, name: "授课管理", img: "images/shouke.png",class:''},
        {id: 3, name: "阅卷管理", img: "images/ceshi.png",class:''},
        {id: 4, name: "作业管理", img: "images/zuoye.png",class:''},
        {id: 5, name: "学生管理", img: "images/student.png",class:''},
      ],
      userInfo:{},
      styleObject:{
        color: 'red',
      },
      classActiveShow:0,
      quickEntryShow:''
    };
  },
  methods: {
    selectOpen(index, indexPath) {
      if(index<6){
        this.showItem((Number(index)-1) || 0)
      }
      // this.showItem((Number(index)-1) || 0)
      let map = {
        '1':'statisticsAnalyse',//统计分析
        '2':'courseAdministrator',  // 授课管理
        '3':'testAdministrator',  // 测试管理
        '4':'workAdministrator',  // 作业管理
        '5':'studentAdministrator',  // 学生管理
        '6':'classAdministrator',  // 班级管理
        '7':'teacherAdministrator',  // 教师管理
        '8-1':'resourceCourseAdministrator',  // 课程管理
        '8-2':'documentAdministrator',  // 文件管理
        '9-1':'questionsList',  // 试题列表管理
        '9-2':'batchChannel',//批量导入
        '9-3':'examinationAdministration',//试卷管理
        '10':'setAccount',//账号设置
        '11':'certificateManage'//证书管理
      }
      this.isComponent = map[index];
    },
  //  快捷入口
    changeSpeediness(index){
      this.classActiveShow=index
      if(index==0){
        this.selectOpen('1');
        this.activeShow='1'
      }else if(index==1){
        this.selectOpen('2');
        this.activeShow='2'
      }else if(index==2){
        this.selectOpen('3');
        this.activeShow='3'
      }else if(index==3){
        this.selectOpen('4');
        this.activeShow='4'
      }else if(index==4){
        this.selectOpen('5');
        this.activeShow='5'
      }
    },
  //  hover
    changeMousOver(index){
      this.showItem(index)
    },
    showItem(index){
      this.classArr.forEach((item, index1)=>{
        this.$set(this.classArr[index1], 'class', '')
      })
      this.$set(this.classArr[index], 'class', 'shortcut' + (Number(index) +1))
    }
  },
  mounted() {
    this.selectOpen('1');
    this.activeShow='1'
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let publishWorkId=sessionStorage.getItem('publishWorkId');
    let questionListId=sessionStorage.getItem('questionListId');
    let creatCourseId=sessionStorage.getItem('creatCourseId');
    let setNumber=sessionStorage.getItem('setNumber');
    let newAddExaminationData=sessionStorage.getItem('newAddExaminationData');
    let coursePT=sessionStorage.getItem('coursePT')
    let courseMySelf=sessionStorage.getItem('courseMySelf')
    let quickEntryShow=sessionStorage.getItem('quickEntryShow');
    this.quickEntryShow=sessionStorage.getItem('quickEntryShow')
    if(publishWorkId){
      this.selectOpen('4');
      this.activeShow='4';
      sessionStorage.removeItem("publishWorkId")
    }
    if(questionListId){
      this.selectOpen('9-1');
      this.activeShow='9-1';
      sessionStorage.removeItem("questionListId")
    }
    if(creatCourseId){
      this.selectOpen('8-1');
      this.activeShow='8-1';
      sessionStorage.removeItem("creatCourseId")
    }
    if(setNumber){
      this.selectOpen('10');
      this.activeShow='10';
      sessionStorage.removeItem("setNumber")
    }
    if(newAddExaminationData){
      this.selectOpen('9-3');
      this.activeShow='9-3';
      sessionStorage.removeItem("newAddExaminationData")
    }
    if(coursePT){
      this.selectOpen('2');
      this.activeShow='2';
      sessionStorage.removeItem("coursePT")
    }
    if(courseMySelf){
      this.selectOpen('8-1');
      this.activeShow='8-1';
      sessionStorage.removeItem("courseMySelf")
    }
    if(quickEntryShow){
      if(quickEntryShow==2){
        this.selectOpen('2');
        this.activeShow='2';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==3){
        this.selectOpen('3');
        this.activeShow='3';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==4){
        this.selectOpen('4');
        this.activeShow='4';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==5){
        this.selectOpen('5');
        this.activeShow='5';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==6){
        this.selectOpen('6');
        this.activeShow='6';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==7){
        this.selectOpen('8-1');
        this.activeShow='8-1';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==8){
        this.selectOpen('10');
        this.activeShow='10';
        sessionStorage.removeItem("quickEntryShow")
      }else if(quickEntryShow==9){
        this.selectOpen('9-3');
        this.activeShow='9-3';
        sessionStorage.removeItem("quickEntryShow")
      }

    }
    document.documentElement.scrollTop = 0;
    this.$other.$emit('setIndexNav',1);
  },
  watch:{
  }

};
</script>
<style scoped>
@import "../../../public/css/classCenter.css";
.el-menu {
  width: 200px !important;
  text-align: center;
}
</style>
