<template>
  <div>
    <div >
      <div class="shoukeTitleFlex">
        <div class="classAdministration">
          <span></span>
          <span>在授课程</span>
        </div>
      </div>
      <div class="authorizedCourse">
        <el-input
            disabled
            v-model="courseSearchName"
            placeholder="课程名称"
            size="small"
        ></el-input>
        <el-button type="primary" size="small"  @click="searchCourse">搜索</el-button>
      </div>
      <div>
        <div class="cousercontentFlex" v-for="(item,index) in courseList" :key="index">
          <div class="coueserImgFlex">
            <img v-if="item.platformCourseInfo&&Object.keys(item.platformCourseInfo).length" :src="item.platformCourseInfo.logo" alt=""/>
            <div>
              <p class="classNameSpan">
                <span>班级名称：</span>
                <span>{{item.classInfo.name}}</span>
              </p>
              <p class="classNameSpan">
                <span>课程名称：</span>
                <span v-if="item.platformCourseInfo&&Object.keys(item.platformCourseInfo).length">{{item.platformCourseInfo.name}}</span>
              </p>
            </div>
          </div>
          <div class="courseBtn" v-if="pigeonholeShow">
            <p>已归档</p>
            <p @click="goCourseDetail(item)">课程详情</p>
          </div>
          <div class="courseBtn" v-if="instructionShow">
            <el-button type="primary" size="small" @click="goCourseDetail(item)" plain>详情</el-button>
            <el-button type="primary" size="small" @click="goCourseCenter(item)">备课</el-button>
            <el-button type="primary" size="small" @click="goPrepareCourse(item)">开始上课</el-button>
          </div>
        </div>
        <div v-if="!courseList.length" style="width: 100%">
          <el-empty :image-size="200"></el-empty>
        </div>
      </div>
      <div style="margin: 30px 0">
        <el-pagination
            class="text_center"
            background
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pagesize"
            layout="total,  prev, pager, next, jumper"
            :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog :visible.sync="courseShowWindow">
      <div class="blackHeight">
        <div class="blackboard">
<!--          <img src="images/palette.png">-->
          <div class="blackPosition">
            <el-menu
                text-color="#fff"
                active-text-color="#49B151"
                :default-active="activeId"
                class="el-menu-vertical-demo"

            >
              <el-submenu v-for="(item, index) in courseDetailArr"
                          :key="item.id" :index="item.id">
                <template slot="title">
                  <span v-if="item.isShowCoursePaprea">{{ item.name }}</span>
                  <span v-if="!item.isShowCoursePaprea" style="color:#ffffff">{{ item.name }}</span>
                </template>
                <el-menu-item-group
                    v-for="(itemChildren, indexChildren) in item.children"
                    :key="indexChildren"
                >
                  <el-menu-item :index="itemChildren.id">
                    <div slot="title" @click="chooseChilrden(itemChildren)">
                      <span class="jieTitle" :style="{color: '#fff'}" v-if="itemChildren.isShowCoursePaprea">{{ itemChildren.name }}</span>
                      <span class="jieHuiTitle" :style="{color: '#fff'}" v-if="!itemChildren.isShowCoursePaprea">{{ itemChildren.name }}</span>
                      <!--        -->
                      <span v-if="activeId&&itemChildren.id==activeId">(已经讲到此处了)</span>
                      <!-- <span class="trySee">试看</span> -->
                      <!--                      <img v-if="itemChildren.freeData==0" src="images/lock.png" alt="" class="lock"/>-->
                    </div>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {classList, pigeonholeCourse, selectLoginTeacherResourceList} from "@/api";
  export  default {
    data(){
      return{
        courseSHow: 1,
        pigeonholeShow: false,
        instructionShow: true,
        courseList:[],
        currentPage:1,
        pagesize:4,
        total:0,
        activeId: '',
        courseDetailArr: [],
        courseShowWindow:false,
        classAdministrationData: [],
        courseData:{},
        courseSearchName:''
      }
    },
    methods:{
      // 授课管理-已授课和归档导航切换,
      changecourseTab(index) {
        this.courseSHow = index;
        this.getCourseList()
        if (index == 1) {
          this.pigeonholeShow = false;
          this.instructionShow = true;
        } else {
          this.pigeonholeShow = true;
          this.instructionShow = false;
        }
      },
      // 去新建课程
      goAddCourse() {
        this.$router.push("/addCourse");
      },
      // 去课程详情
      goCourseDetail(item) {
        sessionStorage.setItem('courseDetail',JSON.stringify(item.platformCourseInfo) )
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
        });
        window.open(routeUrl.href);
      },
    //  获取课程列表
      getCourseList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          page: this.currentPage,
          size: this.pagesize,
          teacherId:userInfo.id
        }
        if(this.courseSHow==1){
          data['isFile']=false
        }else{
          data['isFile']=true
        }
        if(this.courseSearchName){
          data['name']=this.courseSearchName
        }
        pigeonholeCourse(data).then(res => {
          this.courseList=res.data.records;
          this.total=JSON.parse(res.data.total);
        })
      },
      // 分页
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
        this.getCourseList();
      },
    //  去备课中心
      goCourseCenter(item) {
        sessionStorage.setItem('courseDetail',JSON.stringify(item.platformCourseInfo) );
        sessionStorage.setItem('classId',item.classId )
        let routeUrl = this.$router.resolve({
          path: "/prepareCurriculum",
        });
        window.open(routeUrl.href);
      },
      //去上课
      goPrepareCourse(item) {
        this.courseShowWindow = true;
        this.courseData=item
        sessionStorage.setItem('courseDetail',JSON.stringify(item.platformCourseInfo) )
        this.getLoginTeacherResourceList(item);
      },
      //  获取登陆老师章节信息
      getLoginTeacherResourceList(item) {
        let courseItemList = item.platformCourseInfo.courseItemList
        const listToTree = (listArr, pid) => {
          let tree = [];
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseItemList, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
          return tree;
        }
        this.courseDetailArr = listToTree(courseItemList, '0');
        this.activeId = ''
        this.$forceUpdate()
        let data = {
          courseId: item.platformCourseInfo.id,
          classId: item.classId
        };
        selectLoginTeacherResourceList(data).then(res => {
          this.courseDetailArr.forEach((item,index)=>{
            item.children.forEach((item1,index1)=>{
              res.data.forEach(item2=>{
                if(item1.id==item2.id){
                  this.$set(item.children[index1],  "isShowCoursePaprea",true);
                  this.$set(this.courseDetailArr[index],  "isShowCoursePaprea",true);
                  item2.isLastLecture && (this.activeId = String(item2.id))
                }
              })
            })
          })
        })
      },
      //课程资源
      chooseChilrden(item) {
        this.classAdministrationData.forEach(item => {
          if (item.name == this.classValue) {
            this.classId = item.id
          }
        })
        sessionStorage.setItem('courseResourceDetail', JSON.stringify(item));
        if(item.isShowCoursePaprea){
          this.$router.push({
            path: 'speakCourse',
            query: {
              progressId: this.courseData.id,
              className: this.courseData.classInfo.name,
              classId:this.courseData.classId,
              coursePT:'1'
            }
          })
        }else{
          this.courseShowWindow = false;
          sessionStorage.setItem('classId',this.courseData.classId);
          let routeUrl = this.$router.resolve({
            path: "/prepareCurriculum",
          });
          window.open(routeUrl.href);
        }
      },
      //  获取班级列表
      getClassList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          schoolId: userInfo.schoolId,
          page: 1,
          size: 50
        }
        classList(data).then(res => {
          if (res.data.records) {
            this.classAdministrationData = res.data.records;
            this.classValue = res.data.records[0].name
          }
        })
      },
    //  搜索课程
      searchCourse(){
        this.getCourseList()
      }
    },
    mounted(){
      this.getClassList();
      this.getCourseList();
    }
  }
</script>
<style scoped>
@import "../../../../public/css/prepareCourseCenter.css";
</style>
