<template>
  <div class="administrationCourse">
    <div class="classAdministration">
      <span></span>
      <span>课程管理</span>
    </div>
    <div style="display: flex;justify-content: space-between">
      <!-- 课程列表 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="平台"></el-tab-pane>
        <el-tab-pane label="个人"></el-tab-pane>
      </el-tabs>
      <div class="courseAdministrationFlex courseadminMr">
        <el-input placeholder="请输入课程" v-model="seachCourse" @change="seacherCourseList" size="small"></el-input>
        <el-button type="primary" size="small"  @click="seacherCourseList" style="margin-left: 10px">搜索</el-button>
        <el-button type="primary" size="small" @click="goAddCourse" >新建课程</el-button>
      </div>
    </div>
    <div class="courseListFlex courseListMb" v-for="(item,index) in schoolCourseLIstArr" :key="index">
      <div class="courseListFlex">
        <img :src="item.logo" alt="" class="courseZImg"/>
        <div class="courserContent">
          <p>{{item.name}}</p>
<!--          <p>{{item.platformCourseInfo.profiles}}</p>-->
          <p v-if="item.resourceDuration">课程时长:{{parseInt(item.resourceDuration/60) }}分钟</p>
          <p v-if="activeName==0">
            <span>课程难度:</span>
            <span>{{item.difficultyLevel}}</span>
          </p>
        </div>
      </div>
      <div class="courseSpan">
        <el-button type="primary" plain v-if="item.ownerType!=='platform'" @click="deleteCourse(item.id)" size="small">删除课程</el-button>
        <el-button type="primary" plain @click="admendCourse(item)" v-if="activeName==1" size="small">修改课程</el-button>
        <el-button type="primary" plain @click="statisticsManagement(item)" size="small">课程详情</el-button>
        <el-button type="primary" plain @click="goPreperCourse(item)"  v-if="activeName==1" size="small">备课中心</el-button>
        <el-button type="primary" plain @click="teacherGoCourse(item)"  v-if="activeName==1" size="small">开始上课</el-button>
      </div>
    </div>
    <div v-if="!schoolCourseLIstArr.length" style="width: 100%">
      <el-empty :image-size="200"></el-empty>
    </div>
    <div style="margin: 30px 0">
      <el-pagination
          class="text_center"
          background
          @current-change="handleCurrentChange"
          :current-page.sync="coursePage"
          :page-size="courseSize"
          layout="total,  prev, pager, next, jumper"
          :total="courseTotal"
      >
      </el-pagination>
    </div>
    <el-dialog :visible.sync="selectClassCourseWindow" :show-close="true" >
      <div class="selcetClassHeight">
        <div  class="selectClassCourse">
          <p class="selectClassCourseP">选择班级:</p>
          <el-select v-model="classValue" placeholder="请选择上课班级" @change="switchoverProgress">
            <el-option
                v-for="item in classAdministrationData"
                :key="item.id"
                :label="item.name"
                :value="item.name">
            </el-option>
          </el-select>
<!--          <el-button @click="nextCoursechapter">下一步</el-button>-->
        </div>
<!--        章节-->
        <div >
          <div class="blackHeight">
            <div class="blackboard">
<!--              <img src="images/palette.png">-->
              <div class="blackPosition">
                <el-menu
                    text-color="#fff"
                    active-text-color="#49B151"
                    :default-active="activeId"
                    class="el-menu-vertical-demo">
                  <el-submenu v-for="(item, index) in courseDetailArr"
                              :key="item.id" :index="item.id">
                    <template slot="title">
                      <span v-if="item.isShowCoursePaprea">{{ item.name }}</span>
                      <span v-if="!item.isShowCoursePaprea" style="color:#6a6a6a">{{ item.name }}</span>
                    </template>
                    <el-menu-item-group
                        v-for="(itemChildren, indexChildren) in item.children"
                        :key="indexChildren">
                      <el-menu-item :index="itemChildren.id">
                        <div slot="title" @click="chooseChilrden(itemChildren)">
                          <span class="jieTitle" v-if="itemChildren.isShowCoursePaprea">{{ itemChildren.name }}</span>
                          <span class="jieHuiTitle" v-if="!itemChildren.isShowCoursePaprea">{{ itemChildren.name }}</span>
                          <!--        -->
                          <span v-if="activeId&&itemChildren.id==activeId">(已经讲到此处了)</span>
                        </div>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                </el-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  classList,
  courseList,
  deleteTeacherCourse,
  deleteTeacherExercises,
  selectLoginTeacherResourceList,
  courseProgressList
} from "@/api";

  export default {
    data(){
      return{
        courseRadio: "",
        selectClassCourseWindow:false,
        schoolCourseLIstArr:[],
        coursePage:1,
        courseSize:4,
        courseTotal:0,
        seachCourse:'',
        teacherUser:{},
        activeName: 0,
        classValue:'',
        classAdministrationData:[],
        classShow:false,
        coursePaperWindow:false,
        activeId: '',
        courseDetailArr:[],
        courseData:{},
        classId:'',
        progressId:''
      }
    },
    methods:{
      //搜索
      seacherCourseList(){
        this.getCourseAdministrationList();
      },
      // 分页
      handleCurrentChange: function (coursePage) {
        this.coursePage = coursePage;
        this.getCourseAdministrationList();
      },
      // 去新建课程
      goAddCourse() {
        this.$router.push("/addCourse");
        sessionStorage.removeItem("myCourseData");
      },
      //去课程详情
      statisticsManagement(item) {
        if(this.activeName==1){
          sessionStorage.setItem('courseDetail',JSON.stringify(item) )
          let routeUrl = this.$router.resolve({
            path: "/courseDetail",
            query:{
              myCourseDetail:'myCourseDetail'
            }
          });
          window.open(routeUrl.href);
        }else{
          if(item.forEnterprise=='0'){
            sessionStorage.setItem('courseDetail',JSON.stringify(item));
            let routeUrl = this.$router.resolve({
              path: "/courseDetail",
            });
            window.open(routeUrl.href);
          }else{
            sessionStorage.setItem('courseVideoDetails',JSON.stringify(item) )
            let routeUrl = this.$router.resolve({
              path: "/courseVideoDetais",
            });
            window.open(routeUrl.href);
          }
        }
      },
      //tab
      handleClick(tab, event) {
        this.getCourseAdministrationList()
      },
    //  获取课程管理列表
      getCourseAdministrationList(){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          page: this.coursePage,
          size: this.courseSize,
        }
        if(this.activeName==0){
          data['schoolId']=userInfo.schoolId
        }
        if(this.activeName==1){
          data['ownerType']='school_teacher';
          data['ownerId']=userInfo.id
        }
        if(this.seachCourse){
          data.name=this.seachCourse
        }
        courseList(data).then(res => {
          this.schoolCourseLIstArr=res.data.records;
          this.courseTotal=JSON.parse(res.data.total);
        })
      },
    //  修改课程信息
      admendCourse(item){
        this.$router.push({path:'addCourse',query:{id:item.id}})
        sessionStorage.setItem('myCourseData',JSON.stringify(item) )
      },
    //  删除课程
      deleteCourse(id){
        let data={
          id:id
        };
        this.$confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteTeacherCourse(data).then(res=>{
            if(res.code===0){
              this.getCourseAdministrationList();
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }else{
              this.$message.error(res.msg);
            }
          })

        }).catch(() => {

        });
      },
      //  获取班级列表
      getClassList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          schoolId: userInfo.schoolId,
          page: 1,
          size: 50
        }
        classList(data).then(res => {
          if (res.data.records) {
            this.classAdministrationData = res.data.records;
            this.classValue = res.data.records[0].name
          }
        })
      },
      //课程资源
      chooseChilrden(item) {
        sessionStorage.setItem('courseResourceDetail', JSON.stringify(item))
        if(item.isShowCoursePaprea){
          this.$router.push({
            path: 'speakCourse',
            query: {
              progressId: this.progressId?this.progressId:'',
              className:this.classValue,
              classId:this.classId,
              courseMySelf:'1'
            }
          })
        }else{
          this.selectClassCourseWindow=false
          sessionStorage.setItem('classId',this.classId)
          let routeUrl = this.$router.resolve({
            path: "/prepareCurriculum",
          });
          window.open(routeUrl.href);
        }
      },
      //  获取登陆老师章节信息
      getLoginTeacherResourceList(item) {
        let courseItemList = item.courseItemList
        const listToTree = (listArr, pid) => {
          let tree = [];
          listArr.forEach((item, index) => {
            if (item.pid === pid) {
              let child = listToTree(courseItemList, item.id)
              let data = {...item}
              child && child.length && (data['children'] = child)
              tree.push(data)
            }
          })
          return tree;
        }
        this.courseDetailArr = listToTree(courseItemList, '0');
        this.classAdministrationData.forEach(item1 => {
          if (item1.name == this.classValue) {
            this.classId = item1.id
          }
        })
        this.activeId = ''
        this.$forceUpdate()
        let data = {
          courseId: item.id,
          classId: this.classId
        };
        selectLoginTeacherResourceList(data).then(res => {
          this.courseDetailArr.forEach((item,index)=>{
            item.children.forEach((item1,index1)=>{
              res.data.forEach(item2=>{
                if(item1.id==item2.id){
                  this.$set(item.children[index1],  "isShowCoursePaprea",true);
                  this.$set(this.courseDetailArr[index],  "isShowCoursePaprea",true);
                  item2.isLastLecture && (this.activeId = String(item2.id))
                }
              })
            })
          })
        })
      },
    //  去备课
      goPreperCourse(item){
        sessionStorage.setItem('courseDetail',JSON.stringify(item) );
        let routeUrl = this.$router.resolve({
          path: "/prepareCurriculum",
          query: {teacherCourse: 'teacherCourse'}
        });
        window.open(routeUrl.href);
      },
    //  去上课
      teacherGoCourse(item){
        this.classShow=true
        this.selectClassCourseWindow=true;
        this.coursePaperWindow=false
        this.courseData=item
        sessionStorage.setItem('courseDetail',JSON.stringify(item) );
        this.getLoginTeacherResourceList(item);
        this.selectProgressList(item)
      },
      //  查询登录教师自有课程进度列表
      selectProgressList(item){
        let data={
          platformCourseId:item.id,
          classId:this.classId
        };
        courseProgressList(data).then(res=>{
          if(res.code==0&&res.data.records.length){
            this.progressId=res.data.records[0].id
          }
        })
      },
    //切换班级展示不同进度
      switchoverProgress(){
        this.getLoginTeacherResourceList(this.courseData);
        this.selectProgressList(this.courseData)
      }
    },
    mounted() {
      this.getClassList()
      this.getCourseAdministrationList()
    }
  }
</script>
