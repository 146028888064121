<template>
  <div>
    <div v-if="!journalShow">
      <div class="classAdministration">
        <span></span>
        <span>班级管理</span>
      </div>
      <div class="classSearch">
        <el-cascader
            v-model="classCascader"
            :options="classGradeCas"
            :props="props"
            @change="changeClassCascader"
            size="small"
        >
          <template slot-scope="{ node, data }">
            <span>{{ data.classGrade }}</span>
          </template>
        </el-cascader>
        <el-button type="primary" size="small" plain @click="resetSearch">重置</el-button>
        <el-button type="primary" size="small"  @click="searchClass()">搜索</el-button>
        <el-button type="primary" size="small" v-if="userInfo.adminData==1" @click="newConstructionClass">新建</el-button>
      </div>
      <div class="measurementTable" v-if="classAdministrationData.length">
        <el-table
            v-loading="loading"
            :data="classAdministrationData"
            style="width: 100%"
        >
          <el-table-column prop="index" type="index" :index="indexMethod" label="序号" width="100">
          </el-table-column>
          <el-table-column prop="classGrade" label="年级" >
          </el-table-column>
          <el-table-column prop="name" label="班级" >
          </el-table-column>
          <el-table-column prop="startTime" label="开始时间" >
          </el-table-column>
          <el-table-column prop="endTime" label="结束时间" >
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="280">
            <template slot-scope="scope">
              <div class="celerityRead">
                          <el-button type="primary" size="small" plain @click="management(scope.row)"
                          >管理</el-button>
<!--                <el-button type="primary" size="small" plain @click="clssDetail(scope.$index,scope.row.id)"-->
<!--                >详情</el-button>-->
                <el-button type="primary" size="small" plain @click="changeAmendClass(scope.$index,scope.row.id)">详情</el-button>
                <el-button type="primary" size="small" plain @click="changedeleteClass(scope.row.id)">删除</el-button>

              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="classListPageMr">
          <el-pagination
              class="text_center"
              background
              @current-change="handleClassList"
              :current-page.sync="classPage"
              :page-size="classSize"
              layout="total,  prev, pager, next, jumper"
              :total="classTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-if="!classAdministrationData.length" style="width: 100%">
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <div v-if="journalShow">
      <div class="journalFlex">
<!--        <div>-->
<!--          <p-->
<!--              class="journalFlexp"-->
<!--              @click="changeJournal(1)"-->
<!--              :class="checkjournal == 1 ? 'journalActive' : ''"-->
<!--          >-->
<!--            学生日志-->
<!--          </p>-->
<!--          <p-->
<!--              class="journalFlexp"-->
<!--              @click="changeJournal(2)"-->
<!--              :class="checkjournal == 2 ? 'journalActive' : ''"-->
<!--          >-->
<!--            考勤详情-->
<!--          </p>-->
<!--        </div>-->
        <p class="returnClass" @click="returnClass">返回班级</p>
      </div>
      <div>
        <div class="checkMr">
          <el-date-picker
              value-format="yyyy-MM-dd "
              v-model="checkTime"
              type="date"
              placeholder="选择日期"
          >
          </el-date-picker>
          <el-button @click="selectTime()"> 搜索</el-button>
        </div>
        <div class="journalContentFlex">
          <div>
            <p>
              <span class="journalContentTime">{{ nowDate }}</span>
            </p>
            <div
                class="allJournal"
                :class="journalMOreShow ? '' : 'journalMOreActive'"
            >
              <div v-for="(item, index) in rizhiList" class="allJournalp" :key="index"
                 @click="selectRizhi(item)">
                <!-- <p>{{ item.userName?item.userName:item.userNickName }}的日志</p>
                <p v-if="!item.userName&&item.userNickName">{{item.userTelNumber}}的日志</p> -->
                <p>{{ item.userName?item.userName:item.userNickName ? item.userNickName : item. userTelNumber}}的日志</p>
              </div>
<!--              <p v-for="(item, index) in journalArr" :key="index" v-if="checkjournal==2" @click="selectKaoqin(item)">-->
<!--                {{ item.userTelNumber }}的考勤-->
<!--              </p>-->
            </div>
            <div style="margin-top:50px" >
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleRizhiList"
                  :current-page.sync="rizhiPage"
                  :page-size="rizhisize"
                  layout="total,  prev, pager, next"
                  :total="rizhitotal"
              >
              </el-pagination>
            </div>
<!--            <div style="margin-top:50px" v-if="checkjournal==2">-->
<!--              <el-pagination-->
<!--                  class="text_center"-->
<!--                  background-->
<!--                  @current-change="handleKaoqinList"-->
<!--                  :current-page.sync="KaoqinPage"-->
<!--                  :page-size="Kaoqinsize"-->
<!--                  layout="total,  prev, pager, next"-->
<!--                  :total="Kaoqintotal"-->
<!--              >-->
<!--              </el-pagination>-->
<!--            </div>-->
          </div>
          <div class="selectJournalPad">
            <div class="studentJournalFlex" v-if="rizhiUserInfo && rizhiUserInfo.userName && rizhiUserInfo.userTelNumber">
<!--              <p v-if="checkjournal == 2">-->
<!--                {{ checkWorkTime.userName.substr(-2) ? checkWorkTime.userName.substr(-2) : checkWorkTime.userTelNumber.substr(-2) }}</p>-->
              <p>
                {{ rizhiUserInfo.userName.substr(-2) ? rizhiUserInfo.userName.substr(-2) : rizhiUserInfo.userTelNumber.substr(-2) }}</p>
              <div>
                <p >
                  {{ rizhiUserInfo.userName ? rizhiUserInfo.userName : rizhiUserInfo.userTelNumber }}的学习日志</p>
<!--                <p v-if="checkjournal == 2">-->
<!--                  {{ checkWorkTime.userName ? checkWorkTime.userName : checkWorkTime.userTelNumber }}的考勤记录</p>-->
                <!--                <p>2020-9-6 18:03</p>-->
              </div>
            </div>
            <p v-else-if="!rizhiContent || !rizhiContent.mContent">暂无日志内容</p>
            <div >
              <div v-if="rizhiContent && rizhiContent.mContent">
                <p class="stdjourseTitle">日志内容：</p>
                <p>
                  {{ rizhiContent.mContent }}
                </p>
              </div>
              <div v-if="fileUrls && fileUrls.length > 0">
                <p class="stdjourseTitle">图片附件：</p>
<!--                <div v-for="(item,index) in fileUrls" :key="index">-->
<!--                  <img-->
<!--                      :src="item"-->
<!--                      alt=""-->
<!--                      class="jourseImg"-->
<!--                  />-->
<!--                </div>-->
                <el-image
                    style="width: 100px; height: 100px"
                    :src="fileUrls[0]"
                    :preview-src-list="fileUrls">
                </el-image>
              </div>
              <!--              <div class="jourseRead">-->
              <!--                <p></p>-->
              <!--                <p>1人已读</p>-->
              <!--              </div>-->
            </div>
<!--            <div v-if="checkjournal == 2">-->
<!--              <p class="checkingIn" v-if="signInLastTime||signOutLastTime">-->
<!--                考勤要求：学校上课时间 {{ signInLastTime }}—{{ signOutLastTime }}-->
<!--              </p>-->
<!--              <div>-->
<!--                <p class="attendClass">-->
<!--                  <span></span>-->
<!--                  <span>上课:</span>-->
<!--                  <span>{{ checkWorkTime.kaoqinTime ? checkWorkTime.kaoqinTime : '缺卡' }}</span>-->
<!--                </p>-->
<!--                <p class="attendLian"></p>-->
<!--                <p class="attendClass">-->
<!--                  <span></span>-->
<!--                  <span>下课:</span>-->
<!--                  <span>{{ checkWorkTime.kaoqinOUtTime ? checkWorkTime.kaoqinOUtTime : '缺卡' }}</span>-->
<!--                </p>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="newClassShow" :show-close="true" title="">
      <div>
        <div>
          <p class="addClassTitle">{{ classIndexMap[classIndex] }}</p>
          <div class="claaNewname">
            <div>
              <span>年级名称</span>
              <el-select v-model="classValueName" placeholder="请选择" @change="changeClass">
                <el-option
                    v-for="item in classYear"
                    :key="item"
                    :label="item"
                    :value="item"

                >
                </el-option>
              </el-select>
            </div>
            <div class="classNameFlex">
              <span style="margin-right: 34px">班级名称</span>
              <el-input
                  placeholder="请输入班级名称"
                  v-model="className"
              ></el-input>
            </div>
            <div>
              <span>学期年限</span>
              <el-date-picker
                  value-format="yyyy-MM-dd"
                  v-model="timeValue"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="changeClassTime"
              >
              </el-date-picker>
            </div>
<!--            <div>-->
<!--              <span>签到时间</span>-->
<!--              <el-time-picker-->
<!--                  is-range-->
<!--                  v-model="signTime"-->
<!--                  value-format="HH:mm:ss"-->
<!--                  range-separator="至"-->
<!--                  start-placeholder="签到时间"-->
<!--                  end-placeholder="签退时间"-->
<!--                  placeholder="选择时间范围"-->
<!--                  @change='changeSignTime'-->
<!--              >-->
<!--              </el-time-picker>-->
<!--            </div>-->
            <div class="classLogoFlex">
              <span>班级logo</span>
              <custom-upload v-model="classAvatar" bizType='schoolClassLogo'></custom-upload>
            </div>
            <div class="classCodeFlex" v-if="classIndex!==1">
              <div >
                <p>
                  <span>班级邀请码:</span>
                  <span>{{ inviteCode }}</span>
                </p>
                <p></p>
              </div>
              <p class="btnColior" @click="copyCodeNumber(inviteCode)">复制</p>
              <p class="btnColior" @click="openVodeImg()">二维码</p>
            </div>
          </div>
        </div>
        <div>
          <p class="addClassTitle">{{ classIndexMap[classIndex] }}</p>
          <div v-for="(domain,index) in dynamicValidateForm.domains" class="claaNewname">
            <div class="closeClassName" v-if="classIndex === 1 || classIndex === 3">
              <img @click="deleteCourse(domain)" src="images/close.png" alt=""/>
            </div>
            <div>
              <span>授课教师</span>
              <el-select v-model="domain.teacherId" placeholder="请选择">
                <el-option
                    v-for="item in teacherList"
                    :label="item.name?item.name:item.account"
                    :value="item.id"
                    :key="item.id"

                >
                </el-option>
              </el-select>
            </div>
            <div>
              <span>所授课程</span>
              <el-select v-model="domain.platformCourseId" placeholder="请选择" @change="changeCourseData()">
                <el-option
                    v-for="item in courserListArr"
                    :key="item.name"
                    :label="item.name"
                    :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <p class="addcourse" v-if="classIndex === 1 || classIndex === 3" @click="addCourse()">
            +新增课程
          </p>
        </div>
        <div class="courseSubmit" @click="confirmCourse(classIndex)" v-if="classIndex === 1||classIndex === 3">
          <p>确定</p>
        </div>
      </div>
    </el-dialog>
    <div class="codeQs">
      <el-dialog :title="'扫一扫'" :visible.sync="qrcodeShow" width="400px" :destroy-on-close="true"
                 @opened="openQrCodeImg">
        <span class="codeimg" ref="inviteCode"></span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import {
  boundCourseTeacher,
  classList,
  courseList,
  createClassList,
  getClasscourseInfo,
  getClassInfo,
  gradeNameList, teacherList, classNameList, classClocking, getRizhi, amendClass, deleteClass,unbindClassCourse,schoolTeacherList
} from "@/api";
import QRCode from 'qrcodejs2'
import CustomUpload from "@/components/custom-upload";
export default {
  components: {
    CustomUpload,
  },
  data() {
    return {
      classAvatar:"",
      loading: false,
      props: {
        value: 'classGrade',
        label: 'classGrade',
        lazy: true,
        lazyLoad: this.handleChangeGreade
      },
      journalShow: false,
      classCascader: [],
      classIndex: 0,
      newClassShow: false,
      classAdministrationData: [],
      classGradeCas: [],
      classTotal: 0,
      classPage: 1,
      classSize: 6,
      classIndexMap: {1: '添加班级', 2: '班级信息', 3: '修改班级'},
      classValueName: '',
      classYear: [],
      className: "",
      timeValue: "",
      courseLength: 1,
      dynamicValidateForm: {
        domains: [{
          teacherId: '',
          platformCourseId: ''
        }],
      },
      courseId: '',
      teacherId: '',
      courserListArr: [],
      teacherList: [],
      // checkjournal: 2,
      checkTime: "",
      journalMOreShow: false,
      journalArr: [],
      inviteCode: '',
      qrcodeShow: false,
      signInLastTime: '',
      signOutLastTime: '',
      checkWorkTime: {
        kaoqinTime: '',
        kaoqinOUtTime: '',
        userName: '',
        userTelNumber: ''
      },
      classId: '',
      rizhiList: [],
      rizhiContent: {},
      nowDate: '',
      fileUrls: [],
      amendClassId: '',
      rizhiUserInfo: {
        userName: '',
        userTelNumber: ''
      },
      signTime:"",
      rizhiPage:1,
      rizhisize:10,
      rizhitotal:0,
      KaoqinPage:1,
      Kaoqinsize:10,
      Kaoqintotal:0,
      deleteClassid:'',
      newCourseId:'',
      newteacherId:'',
      userInfo:{}
    }
  },
  methods: {
    indexMethod(index) {
      return index + (this.classPage * this.classSize) -5 ;
    },
    // 日志分页
    handleRizhiList: function (rizhiPage) {
      this.rizhiPage = rizhiPage;
      this.getStudentNamelIst()
    },
    //考勤分页
    handleKaoqinList: function (KaoqinPage) {
      this.KaoqinPage = KaoqinPage;
      this.getCaoqinList()
    },
    //选择签到时间
    changeSignTime(){
    },
    //选择年级
    async handleChangeGreade(node, resolve) {
      const {level, value} = node;
      let data = {
        page: 1,
        size: 100,
        classGrade: value
      }
      let res = await classNameList(data)
      if (!res || !res.data || !res.data.records) return;
      const nodes = [];
      res.data.records.forEach((item) => {
        nodes.push({
          classGrade: item.name ? item.name : '暂无名称',
          leaf: level >= 1
        })
      })
      // 通过调用resolve将子节点数据返回，通知组件数据加载完成
      resolve(nodes);
    },
    changeClassCascader(value) {
    },
    //获取年级
    changeClass(value){
      let classValueName=this.classValueName.slice(0,4);
      if(this.classValueName){
        this.timeValue=[classValueName+'-09-01',(Number(classValueName)+3)+'-06-30']
      }
    },
    // 新建班级
    newConstructionClass() {
      this.newClassShow = true;
      this.classIndex = 1;
      this.classValueName="";
      this.className="";
      this.timeValue="";
      this.signTime="";
      this.classAvatar="";
      // this.dynamicValidateForm.domains.forEach(item=>{
      //   item.platformCourseId="";
      //   item.teacherId=""
      // })
      this.dynamicValidateForm.domains.length=1
      this.dynamicValidateForm.domains[0].platformCourseId='';
      this.dynamicValidateForm.domains[0].teacherId=''
    },
    changeCourseData(){
      this.dynamicValidateForm.domains.forEach(item=>{
        this.newCourseId=item.platformCourseId;
        this.newteacherId=item.teacherId
      })

    },
    // 确定新建班级
    confirmCourse(classIndex) {
      if(!this.classValueName){
        this.$message('年级名称不可为空');return
      }
      if(!this.className){
        this.$message('班级名称不可为空');return
      }
      let tag=true
      this.dynamicValidateForm.domains.forEach(item=>{
        if(item.platformCourseId==''||item.teacherId==''){
          tag=false
          this.$message('请绑定课程或教师');
        }
      })
      if(!tag){
        return
      }
      let teacherForCourseList = [];
      let classValueArr = this.classValueName.split('级');
      let newStr = classValueArr.join("");
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        classGrade: newStr,
        name: this.className,
        startTime: this.timeValue[0],
        endTime: this.timeValue[1],
        schoolId: userInfo.schoolId,
        signInLastTime:this.signTime[0],
        signOutLastTime:this.signTime[1],
        logo:this.classAvatar
      }
      if (classIndex == 1) {
        this.dynamicValidateForm.domains.forEach(item=>{
          let domainsData={}
          domainsData['platformCourseId']=item.platformCourseId;
          domainsData['teacherId']=item.teacherId
          teacherForCourseList.push(domainsData)
        })
        createClassList(data).then(res => {
          if (res.code == 0) {
            let query = {
              classId: res.data.id,
              schoolId: userInfo.schoolId,
              teacherForCourseList:teacherForCourseList
            }
            boundCourseTeacher(query).then(res => {
              if(res.code===0){
                this.newClassShow = false;
                this.classValueName = ""
                this.className = ""
                this.timeValue = ""
                this.dynamicValidateForm = {
                  domains: [{
                    teacherId: '',
                    platformCourseId: ''
                  }]
                };
                this.$message({
                  message: "创建成功",
                  type: "success",
                  duration: 2000
                });
              }else{
                this.$message.error(res.msg)
              }
            });
            this.getClassList();
          } else {
            this.$message.error(res.msg)
          }
        });
      } else if (classIndex == 3) {
        let query = {
          classGrade: newStr,
          name: this.className,
          startTime: this.timeValue[0],
          endTime: this.timeValue[1],
          schoolId: userInfo.schoolId,
          id: this.amendClassId,
          signInLastTime:this.signTime[0],
          signOutLastTime:this.signTime[1],
          logo:this.classAvatar
        }

        let submitType = false;
        //
        amendClass(query).then(res => {
          if (res.code == 0) {
            // this.$message({
            //   message: "修改成功",
            //   type: "success",
            //   duration: 2000
            // });
            submitType = true;
            this.newClassShow = false;
            this.getClassList();
          } else {
            this.$message.error(res.msg)
          }
        }).then(d=>{
          let allTeacherCourseList = this.dynamicValidateForm.domains;
          let oldTeacherCourseList = this.dynamicValidateForm.oldDomains;
          let pushTeacherCourseList = [];
          allTeacherCourseList.forEach(allData=>{
            let allDataCourseId = allData.platformCourseId;
            let isPush = true;
            oldTeacherCourseList.forEach(oldData=>{
              let oldDataCourseId = oldData.platformCourseId;
              if(allDataCourseId==oldDataCourseId){
                isPush = false;
              }
            })
            if(isPush){
              pushTeacherCourseList.push(allData)
            }
          })
          if(pushTeacherCourseList.length>0){
            let coursequery = {
              classId: this.amendClassId,
              schoolId: userInfo.schoolId,
              teacherForCourseList: pushTeacherCourseList,
            }
            boundCourseTeacher(coursequery).then(res => {
              if(res.code==0){
                // this.$message({
                //   message: "修改成功",
                //   type: "success",
                //   duration: 2000
                // });
                submitType = true;
                this.newClassShow = false;
                this.classValueName = ""
                this.className = ""
                this.timeValue = ""
                this.dynamicValidateForm = {
                  domains: [{
                    teacherId: '',
                    platformCourseId: ''
                  }]
                };
                this.getClassList();
              }else{
                this.$message.error(res.msg);
                submitType = false;
              }
            });
          }
        }).then(d=>{
          if(submitType){
            this.$message({
              message: "修改成功",
              type: "success",
              duration: 2000
            });
          }
        });
        //绑定课程教师

        if(this.newteacherId&&this.newCourseId){


        }
      }
    },
    // 班级详情
    clssDetail(index, classid) {
      this.classIndex = 2;
      let data = {
        classId: classid
      }
      getClassInfo(data).then(res => {
        this.classValueName = res.data.classGrade;
        this.className = res.data.name;
        this.inviteCode = res.data.inviteCode
        let startTime = res.data.startTime;
        let endTime = res.data.endTime;
        this.timeValue = [startTime, endTime];
        this.classAvatar=res.data.logo;
        let signInLastTime=res.data.signInLastTime;
        let signOutLastTime=res.data.signOutLastTime;
        this.signTime=[signInLastTime,signOutLastTime]
      });
      getClasscourseInfo(data).then(res => {
        this.dynamicValidateForm.domains = res.data.records;
        this.dynamicValidateForm.oldDomains = JSON.parse(JSON.stringify(res.data.records));
        this.newClassShow = true;
      });
    },
    // 修改班级
    changeAmendClass(index, classid) {
      this.classIndex = 3;
      this.amendClassId = classid
      let data = {
        classId: classid
      }
      getClassInfo(data).then(res => {
        this.classValueName = res.data.classGrade;
        this.className = res.data.name;
        this.inviteCode = res.data.inviteCode
        let startTime = res.data.startTime;
        let endTime = res.data.endTime;
        this.timeValue = [startTime, endTime];
        this.classAvatar=res.data.logo;
        let signInLastTime=res.data.signInLastTime;
        let signOutLastTime=res.data.signOutLastTime;
        this.signTime=[signInLastTime,signOutLastTime]
      });
      getClasscourseInfo(data).then(res => {
        this.dynamicValidateForm.domains = res.data.records;
        this.dynamicValidateForm.oldDomains = JSON.parse(JSON.stringify(res.data.records));
        this.newClassShow = true;
      });
    },
    //  获取班级列表
    getClassList() {
      this.loading = true;
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        schoolId: userInfo.schoolId,
        page: this.classPage,
        size: this.classSize
      }
      if (this.classCascader) {
        data['classGrade'] = this.classCascader[0];
        data['name'] = this.classCascader[1]
      }
      classList(data).then(res => {
        if(res.data.records){
          this.classAdministrationData = res.data.records;
        }
        this.classAdministrationData.forEach((item, index) => {
          this.$set(this.classAdministrationData[index], "startTime", item.startTime.slice(0, 10));
          this.$set(this.classAdministrationData[index], "endTime", item.endTime.slice(0, 10));
        })
        this.classTotal = JSON.parse(res.data.total);
        this.loading = false;
      })
    },

    //班级分页
    handleClassList(classPage) {
      this.classPage = classPage;
      this.getClassList();
    },
    //搜索班级
    searchClass() {
      this.getClassList();
    },
    //获取年级名称列表
    getGradeList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        schoolId: userInfo.schoolId
      }
      gradeNameList(data).then(res => {
        this.classGradeCas = res.data.records;
      })
    },
    //转换日期格式
    changeClassTime() {
      this.stuStarTime = this.timeValue[0];
      this.stuEndTime = this.timeValue[1]
    },
    //  新增课程
    addCourse() {
      let newDynamicValidateForm = Object.assign({},this.dynamicValidateForm);
      newDynamicValidateForm.domains.push({
        teacherId: '',
        platformCourseId: ''
      });
      this.dynamicValidateForm = newDynamicValidateForm;
      // this.dynamicValidateForm.domains.push({
      //   teacherId: '',
      //   platformCourseId: ''
      // });
    },
    //获取课程列表
    getCourseList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        page:1,
        size:50,
        schoolId:userInfo.schoolId
      }
      courseList(data).then(res => {
        this.courserListArr = res.data.records
      })
    },
    //查看考勤列表
    // selectKaoqin(item) {
    //   if (item.signInTime) this.checkWorkTime.kaoqinTime = item.signInTime;
    //   if (item.signOutTime) this.checkWorkTime.kaoqinOUtTime = item.signOutTime;
    //   if (item.userName) this.checkWorkTime.userName = item.userName;
    //   if (item.userTelNumber) this.checkWorkTime.userTelNumber = item.userTelNumber
    // },
    //获取教师列表
    getTeacherList() {
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data = {
        page: 1,
        size: 150,
        schoolId:userInfo.schoolId
      }
      schoolTeacherList(data).then(res => {
        this.teacherList = res.data.records;
      })
    },
    // 管理
    management(item) {
      this.classId = item.id
      this.journalShow = true;
      if (item.signInLastTime) {
        this.signInLastTime = item.signInLastTime;
      }
      if (item.signOutLastTime) {
        this.signOutLastTime = item.signOutLastTime
      }
      this.getNowDate();
      this.getStudentNamelIst()
      // this.selectKaoqin(this.journalArr[0])
    },
    //获取考勤列表
    getCaoqinList() {
      let data = {
        classId: this.classId,
        page:this.KaoqinPage,
        size:this.Kaoqinsize
      }
      if (this.checkTime) {
        data['queryStartTime'] = this.checkTime + '00:' + '00:' + '00';
        data['queryEndTime'] = this.checkTime + '23:' + '59:' + '59'
      }
      classClocking(data).then(res => {
        if(res.data.records.length){
          this.journalArr = res.data.records;
          this.Kaoqintotal=JSON.parse(res.data.total)
          this.selectKaoqin(res.data.records[0])
        }
      });
    },
    // 切换日志,考勤tab
    changeJournal(index) {
      if (index == 1) {
        this.getStudentNamelIst()
      }
    },
    //获取学生名称列表
    getStudentNamelIst(){
      let userinfo = localStorage.getItem('userInfo');
      let userInfos = JSON.parse(userinfo)
      let query = {
        schoolId: userInfos.schoolId,
        classId: this.classId,
        page:this.rizhiPage,
        size:this.rizhisize
      }
      if (this.checkTime) {
        query['queryStartTime'] = this.checkTime + '00:' + '00:' + '00';
        query['queryEndTime'] = this.checkTime + '23:' + '59:' + '59'
      }
      getRizhi(query).then(res => {
        this.rizhiList = res.data.records;
        this.rizhitotal=JSON.parse(res.data.total)
        this.selectRizhi(res.data.records[0])
      })
    },
    // 返回班级列表
    returnClass() {
      this.rizhiContent.mContent = ''
      this.journalShow = false;
    },
    //  删除课程
    deleteCourse(item) {
      var index = this.dynamicValidateForm.domains.indexOf(item)
      if (index !== -1) {
        this.dynamicValidateForm.domains.splice(index, 1)
      }
      if(this.dynamicValidateForm.oldDomains&&typeof(this.dynamicValidateForm.oldDomains)!="undefined"){
        var oldIndex = this.dynamicValidateForm.oldDomains.indexOf(item);
        if(oldIndex !== -1){
          this.dynamicValidateForm.oldDomains.splice(oldIndex, 1)
        }
      }
      let newDynamicValidateForm = Object.assign({},this.dynamicValidateForm);
      this.dynamicValidateForm = newDynamicValidateForm;
      if(!item.id){
        return;
      }
      if(this.classIndex==3){
        //解绑
          let query1={
            id:item.id,
          }
          unbindClassCourse(query1).then(res => {
            if(res.code!==0){
              this.$message.error(res.msg)
            }else{
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 2000
              });
              // this.newClassShow = false;
              // this.getClassList();
            }
          })
      }
      // this.deleteClassid=item.id
    },
    //  复制班级邀请码
    copyCodeNumber(codeNumber) {
      //调用copy函数
      this.copy(codeNumber);
    },
    copy(data) {
      // 存储传递过来的数据
      let OrderNumber = data;
      // 创建一个input 元素
      // createElement() 方法通过指定名称创建一个元素
      let newInput = document.createElement("input");
      // 讲存储的数据赋值给input的value值
      newInput.value = OrderNumber;
      // appendChild() 方法向节点添加最后一个子节点。
      document.body.appendChild(newInput);
      // 选中input元素中的文本
      // select() 方法用于选择该元素中的文本。
      newInput.select();
      // 执行浏览器复制命令
      //  execCommand方法是执行一个对当前文档，当前选择或者给出范围的命令
      document.execCommand("Copy");
      // 清空输入框
      newInput.remove();
      // 下面是element的弹窗 不需要的自行删除就好
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    // 生成二维码
    openQrCodeImg() {
      new QRCode(this.$refs.inviteCode, {
        width: 200,
        height: 200,
        text: this.inviteCode,
        render: 'canvas'
      });
    },
    //  打开二维码
    openVodeImg() {
      this.qrcodeShow = true
    },
    //  查看日志
    selectRizhi(item) {
      if (item.userTelNumber) {
        this.rizhiUserInfo.userTelNumber = item.userTelNumber
      }else{
        this.rizhiUserInfo.userTelNumber=""
      }
      if (item.userName) {
        this.rizhiUserInfo.userName = item.userName
      }else{
        this.rizhiUserInfo.userName=""
      }
      this.rizhiContent = item;
      let fileUrls = item.fileUrls || '';
      if(item.fileUrls){
        this.fileUrls = fileUrls.split(',')
      }
    },
    //  获取当前时间
    getNowDate() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var week = date.getDay();//得到周几
      var arr_week = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
      var weeks = arr_week[week];
      if (month < 10) {
        month = "0" + month;
      }
      if (day < 10) {
        day = "0" + day;
      }
      var nowDate = year + "-" + month + "-" + day + '  ' + weeks;
      this.nowDate = nowDate
    },
    //  查询日期
    selectTime() {
      // if (this.checkjournal == 1) {
      //   this.changeJournal(1);
      // } else {
      //   this.getCaoqinList()
      // }
      this.getStudentNamelIst()
    },
    //  删除班级
    changedeleteClass(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {
            id: id
          }
          deleteClass(data).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 2000
              });
              this.getClassList();
            }
          })
        })
        .catch(() => {});
    },
  //  重置
    resetSearch(){
      this.classCascader='';
    }
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getClassList();
    this.getGradeList();
    this.getTeacherList();
    this.getCourseList();
    let myDate = new Date();
    let startYear = myDate.getFullYear() - 5;//起始年份
    let endYear = myDate.getFullYear() + 5;//结束年份
    let year = []
    for (let i = startYear; i <= endYear; i++) {
      year.push(i + '级')
    }
    this.classYear = year;

  },
  watch:{
    'dynamicValidateForm.domains'(newVal,oldVal) {

    }
  }
}
</script>

