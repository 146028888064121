<template>
  <div>
    <div v-if="!studentDetailShow">
      <div class="classAdministration">
        <span></span>
        <span>学生管理</span>
      </div>
      <div class="classSearch">
        <el-cascader
            v-model="classCascader"
            :options="classGradeCas"
            :props="props"
            size="small"
        >
          <template slot-scope="{ node, data }">
            <span>{{ data.classGrade }}</span>
          </template>
        </el-cascader>
        <el-input
            v-model="studentCode"
            placeholder="学生学号"
            size="small"
        ></el-input>
        <el-button type="primary" size="small" plain @click="resetSearch()">重置</el-button>
        <el-button type="primary" size="small" @click="searchStudent">搜索</el-button>
      </div>
      <div class="measurementTable" v-if="studentAdministrationData.length">
        <el-table
            v-loading="loading"
            :data="studentAdministrationData"
            style="width:100%"
        >
          <el-table-column prop="serial" label="序号" width="50px" type="index" :index="indexMethod">
          </el-table-column>
          <el-table-column prop="code" label="学生学号" >
            <template slot-scope="scope">
              <p>
                {{scope.row.studentNumber}}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="学生名称">
            <template slot-scope="scope">
              <p  >
                {{ scope.row.name }}
              </p>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" >
            <template slot-scope="scope">
              <p>{{scope.row.telNumber}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="class" label="所属班级">
            <template slot-scope="scope">
              <p>{{scope.row.classGrade}}-{{scope.row.className}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="time" label="加入时间" >
            <template slot-scope="scope">
              <p>{{scope.row.joinClassTime}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="240">
            <template slot-scope="scope">
              <div class="celerityRead">
                <el-button type="primary" size="small" plain @click="goStudentDetail(scope.row)">查看详情</el-button>
                <el-button type="primary"  size="small" @click="changeRemoveStudent(scope.row)">移出班级</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin: 30px 0">
          <el-pagination
              class="text_center"
              background
              @current-change="handleStudentChange"
              :current-page.sync="stdPage"
              :page-size="stdSize"
              layout="total,  prev, pager, next, jumper"
              :total="stdTotal"
          >
          </el-pagination>
        </div>
      </div>
      <div v-if="!studentAdministrationData.length" style="width: 100%">
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <div v-if="studentDetailShow">
      <div class="studentsDetailFlex">
        <span class="studentsDetailFlexspan">学生详情</span>
        <p class="bianjistuDetail">
          <span v-if="!submitShow" @click="compileStudent">编辑</span>
          <span v-if="submitShow" @click="preserve">保存</span>
          <span @click="stdAdministration">返回</span>
        </p>
      </div>
      <div class="studentDetailFlexs">
        <div class="studentTextFlex" v-if="stdDetailshow">
          <div>
            <p>姓名：{{studentDetail.name?studentDetail.name:''}}</p>
            <p>学号：{{studentDetail.studentNumber?studentDetail.studentNumber:''}}</p>
            <p>班级：{{studentDetail.className}}</p>
            <p v-if="studentDetail.gender=='male'">性别：男</p>
            <p v-if="studentDetail.gender=='female'">性别：女</p>
            <p v-if="studentDetail.gender=='unknown'">性别：未知</p>
          </div>
          <div>
            <p>学制：{{studentDetail.duration?studentDetail.duration:''}}年</p>
            <p>毕(结)业：{{classEndTime > nowTime?'未毕业':"已结业"}}</p>
            <p>出生日期：{{studentDetail.birthday?studentDetail.birthday:''}}</p>
            <p>入学时间：{{studentDetail.joinClassTime?studentDetail.joinClassTime:''}}</p>
          </div>
        </div>
        <div class="studentTextFlexS" v-if="!stdDetailshow">
          <div>
            <p>姓名：<el-input v-model="studentDetail.name" placeholder="姓名"></el-input></p>
            <p>学号：<el-input v-model="studentDetail.studentNumber" placeholder="学号"></el-input></p>
            <p>班级：<el-input v-model="studentDetail.className" penterPriseCenterlaceholder="班级" disabled></el-input ></p>
            <p v-if="studentDetail.gender=='male'" >性别：<el-input placeholder="男" disabled></el-input> </p>
            <p v-if="studentDetail.gender=='female'"  >性别：<el-input placeholder="女" disabled></el-input> </p>
            <p v-if="studentDetail.gender=='unknown'" >性别：<el-input  placeholder="未知" disabled></el-input> </p>
          </div>
          <div>
            <p>学制：<el-input v-model="studentDetail.duration" placeholder="学制"></el-input></p>
            <p class="biye">毕(结)业：<span>{{classEndTime > nowTime?'未毕业':"已结业"}}</span></p>
            <p>出生日期：<el-input placeholder="出生日期" disabled></el-input></p>
            <p>入学时间：<el-input v-model="studentDetail.joinClassTime" placeholder="入学时间"></el-input></p>
          </div>
        </div>
        <div>
          <img :src="studentDetail.headPortraitUrl?studentDetail.headPortraitUrl:'images/zanwuAvatar.png'" alt="" class="stdImg"/>
        </div>
      </div>
      <!-- 所学课程 -->
      <div class="studyCoursemr">
        <div class="studentCourserFy">
          <p class="studyCoursemrP">所学课程</p>
          <el-pagination
              class="text_center"
              background
              @current-change="handleCourseChange"
              :current-page.sync="coursePage"
              :page-size="courseSize"
              layout="total,  prev, pager, next, jumper"
              :total="courseTotal"
          >
          </el-pagination>
        </div>
        <div class="studyCourseOverFlow">
          <div class="studyCourseFlex" v-for="(item,index) in couserList" :key="index">
            <div class="studyCourseFlexs">
              <img :src="item.logo?item.logo:''" alt=""/>
              <div class="studyCourseIMgMr">
                <p>{{item.name}}</p>
                <p>
                  <span v-if="item.teacherUser">主讲教师：{{item.teacherUser.name?item.teacherUser.name:''}}</span>
                  <span>难易度：{{item.difficultyLevel}}</span>
                </p>
              </div>
            </div>
            <p class="checkCourseDet" @click="goCourseDetail(item)">课程详情</p>
          </div>
        </div>
      </div>
      <!-- 近期考试 -->
      <div>
        <p class="studyCoursemrP">近期考试</p>
        <div class="recentlyExamFlex">
          <img src="images/left.png" alt="" @click="moveSwiperLeft"/>
          <div class="examSwiper">
            <div
                :style="{
                          width: examArr.length * 400 + 'px',
                          'margin-left': -400 * swiperCont + 'px',
                        }"
            >
              <div
                  class="kaoshiBg"
                  v-for="(item, i) in examArr"
                  :key="i"
              >
                <p class="kaoshiTitles">
                  <span></span>
                  <span>{{ item.examPapersTestCenterName }}</span>
                </p>
                <p class="scoreFont" v-if="item.progressType=='TBC'">
                  <span>待完成</span>
                </p>
                <p class="scoreFont" v-if="item.progressType=='NR'">
                  <span>未批阅</span>
                </p>
                <p class="scoreFont" v-if="item.progressType=='AR'">
                  <span>{{ item.totalScore }}</span>
                  <span>分</span>
                </p>
<!--                <div class="publishTeacherFlex">-->
<!--                  <div class="publishTeacherFlex">-->
<!--                    <img src="images/teachr.png" alt=""/>-->
<!--                    <span>发布老师：雪儿</span>-->
<!--                  </div>-->
<!--                  <div class="kaoshiPushTime">-->
<!--                    <span>2021.4.29</span>-->
<!--                    <span>13:00</span>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
          </div>
          <img
              src="images/right.png"
              alt=""
              @click="moveSwiperRight"
          />
        </div>
      </div>
      <!-- 统计分析 -->
      <div class="statisticMr">
        <p class="studyCoursemrP">统计分析</p>
        <div class="studentEchartsFlex">
          <div style="width: 48%">
            <echarts id="ExamData" :data="ExamData" :echartsTitle="echartsTitle" :legendShow=false></echarts>
          </div>
          <div style="width: 48%">
            <echarts id="ExamStartData" :data="ExamStartData" :echartsTitle="echartsStartTitle" :legendShow=false></echarts>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import echarts from "@/components/echarts";
import {statisticsStudentexamScore,statisticsStudentexamStar,selectStudentList, belongCourseList, gradeNameList, classNameList, removeStudent,teacherAmendStdInfo,teacherSelectStdExamList} from "@/api";
  export default {
    components:{
      echarts,
    },
    data(){
      return{
        loading:false,
        studentDetailShow: false,
        studentCascader: "",
        studentCode: "",
        studentAdministrationData: [],
        swiperCont: 0,
        examArr: [],
        stdPage:1,
        stdSize:6,
        stdTotal:0,
        studentDetail:{},
        couserList:[],
        coursePage:1,
        courseSize:2,
        courseTotal:0,
        stdDetailshow:true,
        studentInfo:{
          name:'',
          studentNumber:'',
          duration:'',
          admissionTime:''
        },
        submitShow:false,
        classGradeCas:[],
        props: {
          value: 'classGrade',
          label: 'classGrade',
          lazy: true,
          lazyLoad: this.handleChangeGreade
        },
        classCascader:[],
        classEndTime:"",
        nowTime:'',
        echartsTitle:'考试成绩分布图',
        ExamData:[],
        ExamStartData:[],
        echartsStartTitle:'考试难度分布图',
      }
    },
    methods:{
      //编辑
      compileStudent(){
        this.stdDetailshow=false;
        this.submitShow=true
      },
      //保存
      preserve(){
        this.submitShow=false;
        this.stdDetailshow=true
        let data={
          id:this.studentDetail.id
        }
        if(this.studentDetail.name){
          data['name']=this.studentDetail.name
        }
        if(this.studentDetail.studentNumber){
          data['studentNumber']=this.studentDetail.studentNumber
        }
        if(this.studentDetail.duration){
          data['duration']=this.studentDetail.duration
        }
        if(this.studentDetail.admissionTime){
          data['admissionTime']=this.studentDetail.admissionTime
        }
        teacherAmendStdInfo(data).then(res=>{
          if(res.code===0){
            this.$message({
              type: 'success',
              message: '修改成功!'
            });
          }else{
            this.$message.error('失败，请重试');
          }
        })
      },
      // 去学生详情
      goStudentDetail(item) {
        this.studentDetailShow = true;
        this.studentDetail=item;
        this.stuCourseList();
        let classEndTime=new Date(item.classEndTime)
        let newsClassEndTime=classEndTime.getTime();
        this.classEndTime=newsClassEndTime;
        this.nowTime=new Date().getTime();
        this.getTeacherSelectStdExamList();
        this.getStatisticsStudentexamScore();
        this.getStatisticsStudentexamStar()
      },
      // 学生管理面包屑
      stdAdministration() {
        this.studentDetailShow = false;
      },
      moveSwiperLeft() {
        if (this.swiperCont != 0) {
          this.swiperCont--;
        }
      },
      // 近期考试轮播右
      moveSwiperRight() {
        if (this.swiperCont < this.examArr.length - 2) {
          this.swiperCont++;
        }
      },
    //  获取学生列表
      getStudentList(){
        this.loading = true;
        let data={
          page:this.stdPage,
          size:this.stdSize
        }
        if (this.classCascader) {
          data['classGrade'] = this.classCascader[0];
          data['className'] = this.classCascader[1]
        }
        if(this.studentCode){
          data['studentNumber']=this.studentCode
        }
        selectStudentList(data).then(res=>{
          this.studentAdministrationData=res.data.records;
          this.stdTotal=JSON.parse(res.data.total);
          this.loading = false;
        })
      },
      //搜索学生
      searchStudent(){
        this.getStudentList()
      },
      //选择年级
      async handleChangeGreade(node, resolve) {
        const {level, value} = node;
        let data = {
          page: 1,
          size: 100,
          classGrade: value
        }
        let res = await classNameList(data)
        if (!res || !res.data || !res.data.records) return;
        const nodes = [];
        res.data.records.forEach((item) => {
          nodes.push({
            classGrade: item.name ? item.name : '暂无名称',
            leaf: level >= 1
          })
        })
        // 通过调用resolve将子节点数据返回，通知组件数据加载完成
        resolve(nodes);
      },
    //  分页
      handleStudentChange: function (stdPage) {
        this.stdPage = stdPage;
        this.getStudentList();
      },
    //
      indexMethod(index) {
        return index + (this.stdPage * this.stdSize) -5 ;
      },
    //  学生所学课程
      stuCourseList(){
        let data={
          page:this.coursePage,
          size:this.courseSize,
          classId:this.studentDetail.classId
        }
        belongCourseList(data).then(res=>{
          this.couserList=res.data.records
          this.courseTotal=JSON.parse(res.data.total)
        })
      },
    //  所学课程分页
      handleCourseChange: function (coursePage) {
        this.coursePage = coursePage;
        this.stuCourseList();
      },
      //获取年级名称列表
      getGradeList() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          schoolId: userInfo.schoolId
        }
        gradeNameList(data).then(res => {
          this.classGradeCas = res.data.records;
        })
      },
    //  移除学生
      changeRemoveStudent(item){
        this.$confirm("确认移出?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          let data={
            id:item.id,
            classId:item.classId
          }
          removeStudent(data).then(res => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 2000
              });
              this.getStudentList();
            }
          })
        })
        .catch(() => {});
      },
      // 去课程详情
      goCourseDetail(item) {
        sessionStorage.setItem('courseDetail',JSON.stringify(item) );
        let routeUrl = this.$router.resolve({
          path: "/courseDetail",
        });
        window.open(routeUrl.href);
      },
    //  教师查询学生近期考试列表
      getTeacherSelectStdExamList(){
        let data={
          userId:this.studentDetail.id
        };
        teacherSelectStdExamList(data).then(res=>{
          if(res.code===0){
            this.examArr=res.data.records
          }
        })
      },
    //  统计学生所有考试的分数数据
      getStatisticsStudentexamScore(){
        let data={
          userId:this.studentDetail.id
        };
        statisticsStudentexamScore(data).then(res=>{
          this.ExamData = [];
          res.data.forEach((item,index)=>{
            let ExamData={
              value:'',
              name:''
            }
            ExamData.value=item.exam_papers_test_center_number;
            ExamData.name=item.total_score+'分'
            this.ExamData.push(ExamData)
          })
        })
      },
    //  统计学生所有考试的习题星级数据
      getStatisticsStudentexamStar(){
        let data={
          userId:this.studentDetail.id
        };
        statisticsStudentexamStar(data).then(res=>{
          this.ExamStartData = [];
          res.data.forEach((item,index)=>{
            let ExamstartData={
              value:'',
              name:''
            }
            ExamstartData.value=item.exercises_number;
            ExamstartData.name=item.star_number+'星'
            this.ExamStartData.push(ExamstartData)
          })
        })
      },
    //  重置
      resetSearch(){
        this.classCascader='';
        this.studentCode=''
      }
    },
    mounted() {
      this.getStudentList();
      this.getGradeList();
    }
  }
</script>
