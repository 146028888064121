<template>
  <div>
    <div>
      <div class="classAdministration">
        <span></span>
        <span>教师管理</span>
      </div>
      <div class="teacherSeacher">
        <el-input
            size="small"
            v-model="teacherName"
            placeholder="教师名称"
            style="margin-right: 16px"
        ></el-input>
        <el-button size="small" type="primary" @click="searchTeacher">搜索</el-button>
      </div>
      <div class="measurementTable">
        <el-table
            v-loading="loading"
            :data="teacherList"
            style="width: 100%"
        >
          <el-table-column prop="serial" label="序号" type="index" :index="indexMethod" width="100">
          </el-table-column>
          <el-table-column
              prop="teacherName"
              label="教师账号">
            <template slot-scope="scope">
              <p>{{scope.row.account}}</p>
            </template>
          </el-table-column>
          <el-table-column
              prop="teacherName"
              label="教师名称"

          >
            <template slot-scope="scope">
              <p>{{scope.row.name}}</p>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="账号状态" >
            <template slot-scope="scope">
              <el-switch
                  v-model="scope.row.state"
                  active-color="#409EFF"
                  inactive-color="#919191"
                  @change="account(scope.row.id,scope.row.state)"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="phone" label="联系方式" >
              <template slot-scope="scope">
                <p>{{scope.row.telNumber}}</p>
              </template>
          </el-table-column>
          <el-table-column prop="operation" label="操作" width="220px">
            <template slot-scope="scope">
              <div class="celerityRead">
                <el-button type="primary" size="small" plain @click="changeAmendTeacher(scope.row.id)">修改</el-button>
                <el-button type="primary" size="small" @click="resetPassword(scope.row.id)">重置密码</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <template>
          <div style="margin: 30px 0">
            <el-pagination
                class="text_center"
                background
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                layout="total,  prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>
          </div>
        </template>
      </div>
    </div>
    <el-dialog :visible.sync="AmendTeacherWindow" :show-close="true" title="">
      <div class="amendTarcherPad">
        <p class="materialCenter" >教师资料</p>
        <div class="amendTarcherFlex">
          <span >教师名称</span>
          <el-input placeholder="请输入名称" v-model="teacher.name"></el-input>
        </div>
        <div class="amendTarcherFlex">
          <span>联系方式</span>
          <el-input placeholder="请输入联系方式" v-model="teacher.phone"></el-input>
        </div>
        <div class="submitAmendTeacher" @click="preserveMaterial">
          <p>保存</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {schoolTeacherList, invokeAccount, resetPassWord, amendTeacherApi, deleteTeacherCoursePreper} from "@/api";
  export default {
    data(){
      return{
        loading:false,
        teacherList: [],
        AmendTeacherWindow: false,
        currentPage:1,
        pagesize:6,
        total:0,
        teacher:{
          name:'',
          phone:''
        },
        classId:'',
        teacherName:''
      }
    },
    methods:{
      // 修改教师
      changeAmendTeacher(id) {
        this.AmendTeacherWindow = true;
        this.classId=id;
      },
      // 保存资料
      preserveMaterial() {
        this.AmendTeacherWindow = false;
        let data={
          id:this.classId,
          name:this.teacher.name,
          telNumber:this.teacher.phone
        }
        amendTeacherApi(data).then(res=>{
          if(res.code==0){
            this.$message({
              message: '修改成功',
              type: 'success'
            });
            this.getTeacherList()
          }else{
            this.$message.error(res.msg)
          }
        })
      },
      //获取教师列表
      getTeacherList() {
        this.loading = true;
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        let data = {
          page: this.currentPage,
          size: this.pagesize,
          schoolId:userInfo.schoolId
        }
        if(this.teacherName){
          data.name=this.teacherName
        }
        schoolTeacherList(data).then(res => {
          this.teacherList = res.data.records;
          this.total=JSON.parse(res.data.total);
          res.data.records.forEach((item,index)=>{
            if(item.mState==1){
              this.$set(res.data.records[index],  "state",true);
            }else{
              this.$set(res.data.records[index],  "state",false);
            }
          });
          this.loading = false;
        })
      },
      // 分页
      handleCurrentChange: function (currentPage) {
        this.currentPage = currentPage;
        this.getTeacherList();
      },
      indexMethod(index) {
        return index + (this.currentPage * this.pagesize) -5 ;
      },
    //  账号状态
      account(id,state){
        let data={
          id:id
        }
        if(state==true){
          data['mState']=1
        }else{
          data['mState']=2
        }
        invokeAccount(data).then(res => {
          if(res.code==0){
            this.$message({
              message: '设置成功',
              type: 'success'
            });
          }else{
            this.$message.error(res.msg)
          }
        })
      },
    //  重置密码
      resetPassword(id){
        let data={
          id:id
        }
        this.$confirm('确认重置密码?重置后的密码为(exiaozhi123456)。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          resetPassWord(data).then(res=>{
            if(res.code==0){
              this.$message({
                message: '重置成功',
                type: 'success'
              });
            }else{
              this.$message.error(res.msg)
            }
          })
        }).catch(() => {
        });
      },
    //  搜索
      searchTeacher(){
        this.getTeacherList()
      }
    },
    mounted() {
      this.getTeacherList()
    }
  }
</script>
