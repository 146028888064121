<template>
  <div>
    <div class="setAccountFlex">
      <div class="setAccountBgIMG2">
        <p>
          <span>学院云空间</span>
          <span v-if="schoolInfo&&schoolInfo.spaceUseSize ">已用{{ (schoolInfo.spaceUseSize/1024/1024).toFixed(2)}}G</span>
          <span v-else> 已用0 G</span>
          <span v-if="schoolInfo&&schoolInfo.spaceUseSize "> 剩余{{ ((schoolInfo.spaceSize-schoolInfo.spaceUseSize)/1024/1024).toFixed(2)}}G</span>
          <span v-else> 剩余{{ (schoolInfo.spaceSize-0)/1024/1024}} G</span>
        </p>
<!--        <p>联系客服</p>-->
      </div>
      <div class="setAccountBgIMG">
        <div>
          <p>教师账号</p>
          <p>({{schoolInfo.teacherAccountNumber}})</p>
        </div>
      </div>
      <div class="setAccountBgIMG3">
        <div>
          <p>学生账号</p>
          <p>({{schoolInfo.studentAccountNumber}})</p>
        </div>
      </div>
    </div>
<!--    个人信息-->
    <div class="personageInfo">
      <div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="个人信息" name="first">
            <div class="teacherInfoPad">
              <div class="preserveFlex">
                <p @click="changeAmendTeacherInfo" v-if="!amendTeacherInfo">编辑</p>
                <p @click="preserveTracherInfo" v-if="amendTeacherInfo">保存</p>
              </div>
              <div class="teacherInfoFlex">
                <div class="uploadAvatar">
                  <custom-upload v-model="accountAvatar" bizType='headPortrait' aliVideoOssData="0"></custom-upload>
                </div>
                <div class="setAcountTecherNumber">
                  <div class="teacherUserBorder">
                  <p>
                    <span>姓名:</span>
                    <span v-if="!amendTeacherInfo">{{userInfo.name}}</span>
                    <el-input v-if="amendTeacherInfo" placeholder="请输入姓名" v-model="userInfo.name"></el-input>
                  </p>
                  <p>
                    <span>电话:</span>
                    <span v-if="!amendTeacherInfo">{{userInfo.telNumber}}</span>
                    <el-input v-if="amendTeacherInfo" placeholder="请输入电话" v-model="userInfo.telNumber"></el-input>
                  </p>
                  <p>
                    <span>邮箱:</span>
                    <span v-if="!amendTeacherInfo">{{userInfo.mailNumber}}</span>
                    <el-input v-if="amendTeacherInfo" placeholder="请输入邮箱" v-model="userInfo.mailNumber"></el-input>
                  </p>
                  <p>
                    <span>性别:</span>
                    <template>
                      <el-radio v-model="radio" label="1" :disabled="disabled">男</el-radio>
                      <el-radio v-model="radio" label="2" :disabled="disabled">女</el-radio>
                    </template>
                  </p>
                  <p>
                    <span>擅长:</span>
                    <span v-if="!amendTeacherInfo">{{userInfo.goodAt}}</span>
                    <el-input v-if="amendTeacherInfo" placeholder="请输入擅长" v-model="userInfo.goodAt"></el-input>
                  </p>
                  <p>
                    <span style="margin-right: 10px;">简介:</span>
                    <span style="width: 90%" v-if="!amendTeacherInfo">{{userInfo.profiles}}</span>
                    <el-input v-if="amendTeacherInfo"
                              type="textarea"
                              :rows="5"
                              placeholder="请输入简介"
                              v-model="userInfo.profiles"
                              style="width: 300px"
                    ></el-input>
                  </p>
                  <p>
                    <span>个性签名:</span>
                    <span v-if="!amendTeacherInfo">{{userInfo.perAutograph}}</span>
                    <el-input v-if="amendTeacherInfo" placeholder="请输入个性签名" v-model="userInfo.perAutograph"></el-input>
                  </p>
                  <div v-if="!newUserInfo.weixinId" class="boundexWxFlex">
                    <p style="margin-right: 20px;width: 69px;">微信账号:</p>
                    <div style="display: flex;justify-content: space-between;width: 75%;">
                      <div>
                        <img src="images/wxBound1.png"/>
                        <span>未绑定</span>
                      </div>
                      <div>
                        <img src="images/wxBound3.png"/>
                        <span @click="boundWx" style="color: #409EFF;cursor: pointer">绑定微信</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="newUserInfo.weixinId" class="boundexWxFlex">
                    <p style="margin-right: 20px;width: 69px;">微信账号:</p>
                    <div style="display: flex;justify-content: space-between;width: 75%;">
                      <div>
                        <img src="images/wxBound.png"/>
                        <span>已绑定</span>
                      </div>
                      <div>
                        <img src="images/wxBound2.png"/>
                        <span @click="unbundleWx"style="color: #409EFF;cursor: pointer">解除绑定</span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
              </div>

            </div>
          </el-tab-pane>
          <el-tab-pane label="修改密码" name="second">
            <div style="display: flex;justify-content: center">
              <div class="admendPassword">
              <p>
                <span>请输入旧密码</span>
                <el-input style="width: 260px" v-model="password.oldPassword" show-password placeholder="密码长度为6~12位"></el-input>
              </p>
              <p>
                <span>请设置新密码</span>
                <el-input style="width: 260px" v-model="password.newPassword" show-password placeholder="密码长度为6~12位"></el-input>
              </p>
              <p>
                <span>再次输入新密码</span>
                <el-input style="width: 260px" v-model="password.affrimPassword" show-password placeholder="再次输入新密码"></el-input>
              </p>
              <div @click="changePassword()" style="width: 100%;text-align: end;">
                <el-button type="primary" >确定</el-button>
              </div>
            </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="操作记录" name="third">
            <div>
              <el-table
                  :data="teacherManagementRecordArr"
                  style="width: 100%">
                <el-table-column
                    prop="name"
                    label="操作人"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="title"
                    label="操作项"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="ip"
                    label="IP">
                </el-table-column>
                <el-table-column
                    prop="costtime"
                    label="消耗时间">
                </el-table-column>
                <el-table-column
                    prop="requestType"
                    label="终端|浏览器">
                </el-table-column>
              </el-table>
            </div>
            <div style="margin: 30px 0">
              <el-pagination
                  class="text_center"
                  background
                  @current-change="handleoperationChange"
                  :current-page.sync="operationPage"
                  :page-size="operationSize"
                  layout="total,  prev, pager, next, jumper"
                  :total="operationTotal"
              >
              </el-pagination>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog :visible.sync="wxLogin.showWxReg" width="400px" center :show-close="false">
      <wxlogin :appid="wxLogin.appid" :scope="wxLogin.scope" :state="wxLogin.state" :self_redirect="false" :redirect_uri="wxLogin.redirect_uri"></wxlogin>
    </el-dialog>
  </div>
</template>
<script>
import {schoolSetInfo, amendTeacherInfo, amendTeacherPassword, teacherManagementRecord, unBindTeacherWx} from "@/api";
import CustomUpload from "@/components/custom-upload";
import wxlogin from "@/components/vue-wxlogin";
export default {
  components: {
    CustomUpload,
    wxlogin
  },
  data(){
    return{
      wxLogin: {
        showWxReg: false,
        appid:'wx448c5bc5dbbc7c51',
        redirect_uri: 'http%3A%2F%2Fexiaozhi.sdzjds.com%2F',
        scope:'snsapi_login',
        state:"wxLogin"
      },
      operationPage:1,
      operationSize:10,
      operationTotal:0,
      activeName: 'first',
      radio: '1',
      amendTeacherInfo:false,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市'
      },],
      schoolInfo:{},
      userInfo:{
        name:"",
        telNumber:"",
        mailNumber:"",
        goodAt:"",
        perAutograph:"",
        logo:''
      },
      password:{
        oldPassword:"",
        newPassword:"",
        affrimPassword:""
      },
      accountAvatar:"",
      teacherManagementRecordArr:[],
      disabled:true,
      newUserInfo:{}
    }
  },
  methods: {
    handleClick(tab, event) {
    },
    //教师绑定微信
    boundWx(){
      sessionStorage.setItem("teacherBound", 'teacherBound');
      this.wxLogin.showWxReg = true;
    },
    //解除绑定微信
    unbundleWx(){
      let data={
        weixinId:this.newUserInfo.weixinId,
        terminalType:'WEB'
      };
      unBindTeacherWx(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "解绑成功",
            type: "success",
          });
          localStorage.setItem('userInfo',JSON.stringify(res.data));
          this.$cookie.set("userToken", res.data.loginToken);
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  修改密码
    changePassword(){
      if (!/^[a-zA-Z0-9]{6,13}$/.test(this.password.newPassword)) {
        this.$message.error("密码格式不对");
        return;
      }
      if(this.password.newPassword !== this.password.affrimPassword){
        this.$message.error('密码不一致');
        return
      };
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        oldPassword:this.password.oldPassword,
        newPassword:this.password.newPassword,
        id:userInfo.id
      }
      amendTeacherPassword(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }else{
          this.$message.error(res.msg)
        }
      })
      this.password={}
    },
  //  修改教师信息
    changeAmendTeacherInfo(){
      this.amendTeacherInfo=!this.amendTeacherInfo;
      this.disabled=false
    },
    //保存教师信息
    preserveTracherInfo(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        id:userInfo.id,
        schoolId:userInfo.schoolId,
        adminData:userInfo.adminData,
        name:this.userInfo.name,
        telNumber:this.userInfo.telNumber,
        mailNumber:this.userInfo.mailNumber,
        goodAt:this.userInfo.goodAt,
        perAutograph:this.userInfo.perAutograph,
        logo:this.accountAvatar,
        profiles:this.userInfo.profiles
      }
      if(this.radio==1){
        data['gender']='male'
      }else{
        data['gender']='female'
      }
      amendTeacherInfo(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: '修改成功',
            type: 'success'
          });
          this.userInfo.logo=this.accountAvatar
          localStorage.setItem('userInfo',JSON.stringify(this.userInfo));
          this.amendTeacherInfo=false
          this.disabled=true
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    /*获取学校云空间*/
    getSchoolSpace(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        schoolId:userInfo.schoolId
      }
      schoolSetInfo(data).then(res=>{
          this.schoolInfo=res.data;
      })
    },
  //  获取用户个人信息
    getUSerInfo(){
      let cachuserInfo = JSON.parse(localStorage.getItem('userInfo'))
      this.userInfo = Object.assign({},cachuserInfo);
      if(cachuserInfo.gender&&cachuserInfo.gender=='male'){
        this.radior="1"
      }else if(cachuserInfo.gender&&cachuserInfo.gender=='female'){
        this.radio="2"
      }else if(cachuserInfo.gender&&cachuserInfo.gender=='unknown'){
        this.radio=""
      }
    },
  //  操作记录
    getTeacherManagementRecord(){
      let userInfo = JSON.parse(localStorage.getItem('userInfo'))
      let data={
        page:this.operationPage,
        size:this.operationSize
      }
      teacherManagementRecord(data).then(res=>{
        res.data.records.forEach((item,index)=>{
          this.$set(res.data.records[index],'name',userInfo.name?userInfo.name:userInfo.telNumber)
        });
        this.teacherManagementRecordArr=res.data.records;
        this.operationTotal=parseInt(res.data.total)
      })
    },
  //  操作记录分页
    handleoperationChange(operationPage){
      this.operationPage=operationPage;
      this.getTeacherManagementRecord()
    }
  },
  mounted() {
    this.getSchoolSpace();
    this.getUSerInfo();
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if(userInfo&&userInfo.logo){
      this.accountAvatar = userInfo.logo;
    }
    this.newUserInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getTeacherManagementRecord()
  }
}
</script>
<style scoped>
@import "../../../../public/css/setAccount.css";
</style>
